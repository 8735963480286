import React from "react"
import styled, { css } from "styled-components"
import { Link, navigate } from "gatsby"

import Logo from "./Logo"
import TabsMenu from "./TabsMenu"
import MenuButton from "./MenuButton"
import MobileMenu from "./MobileMenu"

import { HOME, HCN_WEB_APP } from "../../navigation/constants"
import Button from "../Button"
import Box from "../Box"

/**
 *
 * @param {Array} menu [menu handler]
 * @param {String} onLogoPath [internal navgation path]
 *
 * [
 *   {
 *     title: "Products",
 *     id: "products",
 *     highlight: true, // optional overrides with attentionColor from theme.
 *     data: [
 *       { title: "Team Analytics", id: "team-analytics", to: "/team-analytics" },
 *       { title: "Human Capital Indexing", id: "hcn-index", to: "/hcn-indexing" },
 *       {
 *         itemSeperator: true,  // Add optional seperator, works on both levels.
 *         id: "sep1",
 *       },
 *       { title: "Mootos", id: "mootos", to: "/mootos" },
 *     ],
 *   },
 *   {
 *     title: "Support",
 *     id: "support",
 *     to: "/support",
 *   },
 * ]
 *
 */

function NavigationBar(props) {
  const { menu = [], onLogoPath, fixed = false, bgColor = "#fff" } = props
  const scrollRef = React.useRef(0)
  const wrapperRef = React.useRef()
  const [nestedTabShow, setNestedTabShow] = React.useState("")
  const [showMobileMenu, setShowMobileMenu] = React.useState(false)
  const [activeMobileItem, setActiveMobileItem] = React.useState("")

  const handleNestedTabShow = React.useCallback(
    item => {
      if (item.id === nestedTabShow) {
        return setNestedTabShow("")
      } else {
        return setNestedTabShow(item.id)
      }
    },
    [nestedTabShow]
  )

  const handleMobileMenu = React.useCallback(
    item => {
      if (item.id === activeMobileItem) {
        return setActiveMobileItem("")
      } else {
        return setActiveMobileItem(item.id)
      }
    },
    [activeMobileItem]
  )

  const handleClose = React.useCallback(() => {
    setNestedTabShow("")
  }, [])

  const handleOpenMobileMenu = React.useCallback(() => {
    if (showMobileMenu) {
      if (!fixed) {
        wrapperRef.current.style.position = "relative"
        wrapperRef.current.style.top = ""
      }
      setShowMobileMenu(false)
    } else {
      if (!fixed) {
        scrollRef.current = window.scrollY
        wrapperRef.current.style.position = "fixed"
        wrapperRef.current.style.top = 0
      }
      setShowMobileMenu(true)
    }
  }, [showMobileMenu, fixed])

  const handleNavigate = React.useCallback(
    item => {
      if (item.to || item.uri) {
        setNestedTabShow("")
        setActiveMobileItem("")
        if (showMobileMenu) {
          handleOpenMobileMenu()
        }
        if (item.to) {
          navigate(item.to)
        }
        if (item.uri) {
          window.open(item.uri, "_blank")
        }
      }
    },
    [showMobileMenu, handleOpenMobileMenu]
  )

  return (
    <Wrapper
      fixed={fixed}
      ref={wrapperRef}
      nestedTabShow={nestedTabShow}
      bgColor={bgColor}
    >
      <Nav nestedTabNavShow={!!nestedTabShow}>
        <Toolbar>
          <LogoWrapper to={onLogoPath || HOME}>
            <Logo height={28} />
          </LogoWrapper>
          <TabsMenu
            menu={menu}
            onButton={handleNestedTabShow}
            onNavigate={handleNavigate}
            onClose={handleClose}
            nestedActive={nestedTabShow}
          />
          <Box
            sx={css`
              flex: 1;
            `}
          />
          <Button
            item={{
              title: "Login",
              id: "topnav-hcn-login-navigation",
              uri: HCN_WEB_APP,
            }}
            sx={css`
              align-self: auto;
              display: none;
              font-weight: 700;
              letter-spacing: 0.05em;
              ${({ theme }) => theme.breakpoint.up.md} {
                display: block;
              }
            `}
            variant="contained"
            title="Login"
            size="small"
            onClick={handleNavigate}
          />
          <MenuButton onClick={handleOpenMobileMenu} open={showMobileMenu} />
          <MobileMenu
            menu={menu}
            open={showMobileMenu}
            nestedActive={activeMobileItem}
            onButton={handleMobileMenu}
            onNavigate={handleNavigate}
          />
        </Toolbar>
      </Nav>
    </Wrapper>
  )
}

const fixedCss = css`
  position: fixed;
  top: 0;
`

const absoluteCss = css`
  position: relative;
`

const Wrapper = styled.div`
  width: 100%;
  z-index: 1100;
  ${({ fixed }) => (fixed ? fixedCss : absoluteCss)}
  border-bottom-width: ${({ nestedTabShow }) => (nestedTabShow ? 1 : 0)}px;
  border-bottom-style: solid;
  border-bottom-color: rgba(27, 27, 27, 0.15);
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "transparent")};
`

const Nav = styled.nav`
  background-color: rgb(255, 255, 255);
  height: ${({ nestedTabNavShow, theme }) =>
    nestedTabNavShow
      ? theme.overrides.NavigationBar.height * 2
      : theme.overrides.NavigationBar.height}px;
  position: relative;
  color: ${({ theme }) => theme.overrides.NavigationBar.color};
`

const Toolbar = styled.div`
  padding: 0 24px;
  align-items: center;
  box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 20%);
  display: flex;
  height: ${({ theme }) => theme.overrides.NavigationBar.height}px;
  color: inherit;
`

const LogoWrapper = styled(Link)`
  color: inherit;
  display: flex;
  text-decoration: none;
`

export default NavigationBar
