export const validateEmail = email => {
  return new Promise((resolve, reject) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (re.test(email.toLowerCase())) {
      resolve()
    } else {
      reject({ code: 422, message: "not a valid email" })
    }
  })
}
