import React, { useCallback } from "react"
import { graphql, navigate } from "gatsby"
import { css } from "styled-components"

import Layout from "../layouts/Primary"
import { HeroSection2 } from "../components/HeroSection"
import Section from "../components/Section"
import CenterContent from "../components/CenterContent"
import OpenPositions from "../components/OpenPositions"

import { CAREER } from "../navigation/constants"

function Career(props) {
  const { data } = props

  const handleOnJobPost = useCallback(item => {
    navigate(`${CAREER}/p/${item.slug}`)
  }, [])

  return (
    <Layout
      title="Career at 123abc.com"
      url={CAREER}
      description="If you love moving fast, working smart, and solving big challenges in the world of talent development, let’s chat."
    >
      <HeroSection2
        title={["Join our team"]}
        paragraph="If you love moving fast, working smart, and solving big challenges in the world of talent development, let’s chat."
        // backgroundImage="https://firebasestorage.googleapis.com/v0/b/abc-web-45f9b.appspot.com/o/assets%2Fvector_bg.svg?alt=media&token=2aaeee04-532f-4d4d-b568-445bf106faff"
        bgColor="#7ebf36"
        bgX="50%"
        bgY="100%"
      />
      <Section id="we-are" bgColor="#fff">
        <CenterContent
          title="Who we are"
          paragraph={[
            "We are a remote team of passionate individuals committed to improving the way people grow professionally. Just as 123abc's tools are innovative, diverse, and empowering, so too is our company. We believe collaboration is the key to success, no matter how many hats we have to wear.",
            "Each team member has a huge opportunity to make a big impact, and together, as a distributed and connected team, we support each other in every area of the company to achieve it.",
          ]}
        />
      </Section>
      <Section
        sx={css`
          background-color: ${({ theme }) => theme.palette.green.background};
        `}
      >
        <OpenPositions
          title="Current Job Openings"
          positions={data.posts.edges}
          onClick={handleOnJobPost}
          maxWidth="xl"
        />
      </Section>
    </Layout>
  )
}

export default Career

export const postQuery = graphql`
  query {
    posts: allGhostPost(
      sort: { order: ASC, fields: published_at }
      filter: { tags: { elemMatch: { name: { in: "#123abcJob" } } } }
    ) {
      edges {
        node {
          slug
          id
          title
          tags {
            name
          }
        }
      }
    }
  }
`
