import React from "react"
import styled from "styled-components"

const SvgIcon = React.forwardRef(function SvgIcon(props, ref) {
  const { children, viewBox = "0 0 24 24", color = "inherit", ...rest } = props
  return (
    <Svg viewBox={viewBox} ref={ref} color={color} {...rest}>
      {children}
    </Svg>
  )
})

export default function createSvgIcon(path, name, viewBox) {
  const Component = (props, ref) => (
    <SvgIcon ref={ref} viewBox={viewBox} {...props}>
      {path}
    </SvgIcon>
  )

  return React.memo(React.forwardRef(Component))
}

const Svg = styled.svg`
  fill: currentColor;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  flex-shrink: 0;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 24)}px;
  ${({ size, theme }) => size && `font-size: ${theme.fontSize[size]}`};
  color: ${({ color }) => (color ? color : "inherit")};
`
