import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import BlogPost from "../components/BlogPost"
import TextLink from "../components/TextLink"

import Layout from "../layouts/Primary"

import { BLOG, SITE_URL } from "../navigation/constants"

function Pagination(props) {
  const { prev, next } = props
  return (
    <Nav>
      <NavItem newer>
        {next && (
          <>
            <NavTitle>Newer Post</NavTitle>
            <TextLink to={next.url}>{next.title}</TextLink>
          </>
        )}
      </NavItem>
      <NavItem older>
        {prev && (
          <>
            <NavTitle>Older Post</NavTitle>
            <TextLink to={prev.url} color="primary">
              {prev.title}
            </TextLink>
          </>
        )}
      </NavItem>
    </Nav>
  )
}

const Nav = styled.nav`
  margin-top: ${({ theme }) => theme.spacing[12]};
  padding: 0 ${({ theme }) => theme.spacing[4]};
  ${({ theme }) => theme.device.md} {
    padding: 0 ${({ theme }) => theme.spacing[8]};
    margin-top: ${({ theme }) => theme.spacing[18]};
  }
`

const NavItem = styled.div`
  ${({ theme, older }) => older && `padding-left: ${theme.spacing[4]}`};
  ${({ older }) => older && `text-align: right`};
  ${({ theme, newer }) => newer && `padding-right: ${theme.spacing[4]}`};
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  width: 50%;
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  line-height: ${({ theme }) => theme.lineHeight.relaxed};
`

const NavTitle = styled.span`
  display: block;
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing[3]};
`

function Post({ data, pageContext }) {
  const { prev, next } = pageContext
  return (
    <Layout
      title={data.post.title}
      url={`${BLOG}/${data.post.slug}`}
      description={data.post.plaintext}
      canonicalURL={`${BLOG}/${data.post.slug}`}
    >
      <Wrapper>
        <BlogPost post={data.post} />
        <Pagination prev={prev} next={next} />
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing[10]};
  padding-bottom: ${({ theme, list }) => (list ? 0 : theme.spacing[10])};
  max-width: 960px;
  margin: auto;
  ${({ theme }) => theme.device.sm} {
    padding-top: ${({ theme }) => theme.spacing[8]};

    padding-bottom: ${({ theme, list }) => (list ? 0 : theme.spacing[8])};
  }
  ${({ theme }) => theme.device.md} {
    padding-top: ${({ theme }) => theme.spacing[24]};

    padding-bottom: ${({ theme, list }) => (list ? 0 : theme.spacing[24])};
  }
  ${({ theme }) => theme.device.lg} {
  }
  ${({ theme }) => theme.device.xl} {
  }
`

export default Post

export const postQuery = graphql`
  query($id: String!) {
    post: ghostPost(id: { eq: $id }) {
      slug
      id
      title
      excerpt
      reading_time
      published_at(formatString: "DD MMMM YYYY")
      authors {
        id
        name
      }
      feature_image
      html
      plaintext
    }
  }
`
