import React, { useCallback, useState } from "react"
import { connect } from "react-redux"
import { css } from "styled-components"
import { Form, Field } from "react-final-form"

import { H3 } from "../Typography"
import Box from "../Box"
import Button from "../Button"
import Input from "../Input"

import { delay } from "../../utils/common"

import { careerIntroductionSuccessAction } from "../../containers/career/actions"

import SliderQuestion from "./SliderQuestion"

import firebase from "../../service/firebase"

function JobIntroduce(props) {
  const { maxWidth, title, careerIntroductionSuccess, intro, id } = props
  const [processing, setProcessing] = useState(false)

  const hasIntroduced = Boolean(intro)

  const onSubmit = useCallback(async data => {
    try {
      setProcessing(true)
      const applicationData = Object.assign({}, data, {
        role: { id, title: formData[id].title },
        timestamp: Date.now(),
      })
      await Promise.all([
        firebase.doPostJobApplication(applicationData),
        delay(2200),
      ])
      careerIntroductionSuccess({ id, input: { data } })
    } catch (err) {
      console.log(err)
    } finally {
      setProcessing(false)
    }
  }, [])

  const isEmail = value => {
    if (value) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return Boolean(re.test(value.toLowerCase()))
    }
  }

  const handleFieldValidate = (val, _, field) => {
    if (field.isRequired && !val) {
      return "Required"
    }
    if (field.type === "email" && !isEmail(val)) {
      return "Not a valid email"
    }
  }

  return (
    <Box
      sx={css`
        max-width: ${({ theme }) =>
          maxWidth ? theme.screens[maxWidth] : theme.screens.md};
        margin: 0 auto;
      `}
    >
      <Box>
        <H3>{title}</H3>
        <Form
          initialValues={intro && intro}
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              {formData[id].data.map(section => {
                return (
                  <Box key={section.id} mt={8} as="section">
                    {section.title && <Box mb={5}>{section.title}</Box>}
                    {section.description && (
                      <Box mb={5}>{section.description}</Box>
                    )}
                    {section.data.map(field => {
                      if (field.component === "slider") {
                        return (
                          <Field
                            key={field.id}
                            name={field.name}
                            initialValue={field.initialValue}
                            item={field}
                            disabled={submitting || processing || hasIntroduced}
                            component={SliderQuestion}
                          />
                        )
                      }
                      if (field.component === "input") {
                        return (
                          <Box key={field.id}>
                            <Field
                              name={field.name}
                              placeholder={field.placeholder}
                              type={field.type}
                              component={Input}
                              variant="outlined"
                              bgColor="green"
                              disabled={
                                submitting || processing || hasIntroduced
                              }
                              isRequired={field.isRequired}
                              validate={(val, allFields) =>
                                handleFieldValidate(val, allFields, field)
                              }
                              sx={css`
                                max-width: ${({ theme }) => theme.screens.sm};
                              `}
                            />
                          </Box>
                        )
                      }
                      return null
                    })}
                  </Box>
                )
              })}
              <Button
                mt={8}
                title={hasIntroduced ? "Introduction Received" : "Send"}
                type="submit"
                disabled={submitting || processing || hasIntroduced}
                processing={submitting || processing}
              />
            </form>
          )}
        />
      </Box>
    </Box>
  )
}

JobIntroduce.defaultProps = {
  title: "Introduce yourself",
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.id
  return {
    intro: state.career.introduced && state.career.introduced[id],
  }
}

const actions = dispatch => ({
  careerIntroductionSuccess: introduction =>
    dispatch(careerIntroductionSuccessAction(introduction)),
})

export default connect(mapStateToProps, actions)(JobIntroduce)

const formData = {
  "2f6a71ba-a3e0-480c-8b67-e224aaf64415": {
    id: "2f6a71ba-a3e0-480c-8b67-e224aaf64415",
    title: "Frontend Engineer @ Human Cloud",
    data: [
      {
        id: "self-assess",
        description: "Please tell us about your level in",
        data: [
          {
            id: "english",
            title: "English",
            name: "self.english",
            component: "slider",
            initialValue: 0,
            data: [
              {},
              { label: "Limited" },
              { label: "Can socialize" },
              { label: "Can negotiate" },
              { label: "Native" },
            ],
          },
          {
            id: "javascript",
            title: "Javascript",
            name: "self.javascript",
            component: "slider",
            initialValue: 0,
            data: [
              {},
              { label: "Can with support" },
              { label: "Independent" },
              { label: "Advanced" },
              { label: "Can teach" },
            ],
          },
          {
            id: "node",
            title: "Node",
            name: "self.node",
            component: "slider",
            initialValue: 0,
            data: [
              {},
              { label: "Can with support" },
              { label: "Independent" },
              { label: "Advanced" },
              { label: "Can teach" },
            ],
          },
          {
            id: "graphql",
            title: "GraphQL server",
            name: "self.graphql",
            component: "slider",
            initialValue: 0,
            data: [
              {},
              { label: "Can with support" },
              { label: "Independent" },
              { label: "Advanced" },
              { label: "Can teach" },
            ],
          },
          {
            id: "project-management",
            title: "Break projects down in manageable tasks",
            name: "self.overview",
            component: "slider",
            initialValue: 0,
            data: [
              {},
              { label: "Can with support" },
              { label: "Independent" },
              { label: "Advanced" },
              { label: "Can teach" },
            ],
          },
        ],
      },
      {
        id: "location",
        description: "Where do you live?",
        data: [
          {
            id: "residency",
            placeholder: "London, UK",
            name: "location.residency",
            component: "input",
            type: "text",
            isRequired: true,
          },
        ],
      },
      {
        id: "contact",
        description: "And an email where we can reach you!",
        data: [
          {
            id: "email",
            placeholder: "name@email.com",
            name: "contact.email",
            component: "input",
            type: "email",
            isRequired: true,
          },
        ],
      },
    ],
  },
  "1437c186-3983-4dcd-9c56-c69a15238f69": {
    id: "1437c186-3983-4dcd-9c56-c69a15238f69",
    title: "Marketing & Communications Manager",
    data: [
      {
        id: "self-assess",
        description: "Please tell us about your level in",
        data: [
          {
            id: "english",
            title: "English",
            name: "self.english",
            component: "slider",
            initialValue: 0,
            data: [
              {},
              { label: "Limited" },
              { label: "Can socialize" },
              { label: "Can negotiate" },
              { label: "Native" },
            ],
          },
          {
            id: "storytelling",
            title: "Tell and write engaging stories",
            name: "self.storytelling",
            component: "slider",
            initialValue: 0,
            data: [
              {},
              { label: "Can with support" },
              { label: "Independent" },
              { label: "Advanced" },
              { label: "Can teach" },
            ],
          },
          {
            id: "people-management",
            title: "Manage people",
            name: "self.people-management",
            component: "slider",
            initialValue: 0,
            data: [
              {},
              { label: "Can with support" },
              { label: "Independent" },
              { label: "Advanced" },
              { label: "Can teach" },
            ],
          },
          {
            id: "google-analytics",
            title: "Use google analytics",
            name: "self.google-analytics",
            component: "slider",
            initialValue: 0,
            data: [
              {},
              { label: "Can with support" },
              { label: "Independent" },
              { label: "Advanced" },
              { label: "Can teach" },
            ],
          },
          {
            id: "project-management",
            title: "Break projects down in manageable tasks",
            name: "self.overview",
            component: "slider",
            initialValue: 0,
            data: [
              {},
              { label: "Can with support" },
              { label: "Independent" },
              { label: "Advanced" },
              { label: "Can teach" },
            ],
          },
        ],
      },
      {
        id: "location",
        description: "Where do you live?",
        data: [
          {
            id: "residency",
            placeholder: "London, UK",
            name: "location.residency",
            component: "input",
            type: "text",
            isRequired: true,
          },
        ],
      },
      {
        id: "contact",
        description: "And an email where we can reach you!",
        data: [
          {
            id: "email",
            placeholder: "name@email.com",
            name: "contact.email",
            component: "input",
            type: "email",
            isRequired: true,
          },
        ],
      },
    ],
  },
}
