import React, { useCallback } from "react"
import { css } from "styled-components"

import { H2, SubTitle } from "../Typography"

import Box from "../Box"

function CenterContent(props) {
  const { title, paragraph } = props

  const getParagraph = useCallback(() => {
    if (typeof paragraph === "string") {
      return paragraph
    }
    if (Array.isArray(paragraph)) {
      return paragraph.map((t, i) => {
        if (paragraph.length - i === 1) {
          return <span key={t}>{t}</span>
        }
        return (
          <span key={t}>
            <span>{t}</span>
            <br />
            <br />
          </span>
        )
      })
    }
    return null
  }, [paragraph])

  return (
    <Box
      pr={3}
      pl={3}
      center
      sx={css`
        max-width: 624px;
        text-align: center;
      `}
    >
      <Box mb={6}>
        <H2>{title}</H2>
      </Box>
      <Box
        sx={css`
          text-align: left;
        `}
      >
        <SubTitle>{getParagraph()}</SubTitle>
      </Box>
    </Box>
  )
}

export default CenterContent
