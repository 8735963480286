import React from "react"
import styled from "styled-components"

function Caret(props) {
  const { active } = props
  return (
    <Svg active={active}>
      <path d="M1.41 0L6 4.58L10.59 0L12 1.41L6 7.41L0 1.41L1.41 0Z"></path>
    </Svg>
  )
}

const Svg = styled.svg.attrs(() => ({
  height: 8,
  width: 12,
}))`
  fill: currentColor;
  ${({ active }) => active && "transform: rotate(180deg) translate(0, 1px)"};
`

export default Caret
