import React from "react"
import styled, { css } from "styled-components"

import { H3 } from "../Typography"
import Box from "../Box"

import ListItem from "./ListItem"

function OpenPositions(props) {
  const { positions, onClick, title, maxWidth } = props
  return (
    <Box center pl={5} pr={5} maxWidth={maxWidth}>
      <H3 mb={5}>{title}</H3>
      <Box
        pl={5}
        pr={5}
        sx={css`
          background-color: #fff;
          border-radius: 8px;
          box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
            0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
        `}
      >
        {positions.map(({ node }, i) => {
          if (positions.length - i === 1) {
            return <ListItem key={node.id} item={node} onClick={onClick} />
          }
          return (
            <div key={node.id}>
              <ListItem key={node.id} item={node} onClick={onClick} />
              <Divider opacity={0.52} color="#d2d2d2" />
            </div>
          )
        })}
      </Box>
    </Box>
  )
}

const Divider = styled.hr`
  width: 100%;
  border-color: ${({ color }) => color || "#d3d3d3"};
  color: ${({ color }) => color || "#d3d3d3"};
  border-width: 0;
  border-top-width: 1px;
  margin: 0;
  opacity: ${({ opacity, theme }) =>
    opacity ? opacity : theme.palette.divider.opacity};
`

OpenPositions.defaultProps = {
  positions: [],
}

export default OpenPositions
