import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

import ButtonBase from "../ButtonBase"

import Loader from "../Loader"

// VARIANT
const variantContainedCss = css`
  background-image: none;
  background-color: ${({ theme, color, tone }) =>
    theme.palette[color][tone || "main"]};
  color: ${({ theme, color }) => theme.palette[color].contrastText};
  border: 0px;
`

const variantOutlinedCss = css`
  background-image: none;
  background-color: transparent;
  border-width: 1px;
  border-color: ${({ theme, color, tone }) =>
    theme.palette[color][tone || "main"]};
  border-style: solid;
  color: inherit;
`

// COLOR
const cssColorContained = css``

const cssColorOutlined = css``

// FORM
const cssRounded = css`
  border-radius: ${({ radius }) => (radius ? radius : 24)}px;
`

// SIZE

const btnSize = {
  small: 36,
  medium: 48,
  large: 62,
}

const cssSizeSmall = css`
  padding: 0px 36px;
  height: ${btnSize.small}px;
  min-width: 100px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.sm};
  letter-spacing: ${({ theme }) => theme.letterSpacing.normal};
`

const cssSizeMedium = css`
  padding: 0px 36px;
  height: ${btnSize.medium}px;
  min-width: 144px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.base};
  letter-spacing: ${({ theme }) => theme.letterSpacing.normal};
`
const cssSizeLarge = css`
  padding: 0px 36px;
  height: ${btnSize.large}px;
  min-width: 144px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.lg};
  letter-spacing: ${({ theme }) => theme.letterSpacing.wide};
`

// ADDED PROPERTIES
const margin = css`
  ${({ theme, mt, mx }) =>
    (mx || mt) && `margin-top: ${theme.spacing[mx || mt]}`};
  ${({ theme, mb, mx }) =>
    (mx || mb) && `margin-bottom: ${theme.spacing[mx || mb]}`};
  ${({ theme, ml, my }) =>
    (my || ml) && `margin-left: ${theme.spacing[my || ml]}`};
  ${({ theme, mr, my }) =>
    (my || mr) && `margin-right: ${theme.spacing[my || mr]}`};
`

function getVariant(variant) {
  if (variant === "contained") {
    return variantContainedCss
  } else if (variant === "outlined") {
    return variantOutlinedCss
  } else {
    return variantContainedCss
  }
}

function getColor(variant) {
  if (variant === "contained") {
    return cssColorContained
  } else if (variant === "outlined") {
    return cssColorOutlined
  } else {
    return cssColorContained
  }
}

function getSize(size) {
  if (size === "small") {
    return cssSizeSmall
  } else if (size === "medium") {
    return cssSizeMedium
  } else if (size === "large") {
    return cssSizeLarge
  } else {
    return cssSizeMedium
  }
}

function Button(props) {
  const {
    children,
    onClick,
    variant,
    color,
    size,
    fullWidth,
    processing,
    item,
    title,
    radius,
    ...rest
  } = props
  const [showLoader, setShowLoader] = React.useState(false)

  const handleOnClick = React.useCallback(() => {
    if (item) {
      onClick(item)
    } else {
      onClick()
    }
  }, [item, onClick])

  const handleLoaderOnChange = React.useCallback(({ show }) => {
    setShowLoader(show)
  }, [])

  const colorPath = React.useMemo(() => {
    const paths = color.split(".")
    const c = paths[0].toLowerCase()
    const t = (paths[1] && paths[1].toLowerCase()) || undefined
    return { color: c, tone: t }
  }, [color])

  return (
    <StyledButton
      onClick={handleOnClick}
      variant={variant}
      size={size}
      fullwidth={fullWidth}
      processing={processing}
      radius={radius}
      {...colorPath}
      {...rest}
    >
      <Title showLoader={showLoader}>{title}</Title>
      {processing && (
        <LoaderOuter showLoader={showLoader}>
          <LoaderInner>
            <Loader loadingDelay={600} loaderOnChange={handleLoaderOnChange} />
          </LoaderInner>
        </LoaderOuter>
      )}
    </StyledButton>
  )
}

const StyledButton = styled(ButtonBase)`
  overflow: hidden;
  position: relative;
  text-align: center;
  white-space: nowrap;
  font-family: ${({ theme }) => theme.fontFamily.headings};
  text-decoration: none;
  ${cssRounded};
  ${({ variant }) => getVariant(variant)};
  ${({ color, variant }) => getColor(color, variant)};
  ${({ size }) => getSize(size)};
  ${({ fullwidth }) => fullwidth && "width: 100%"};
  ${margin};
  & :disabled {
    opacity: ${({ theme }) => theme.palette.action.disabledOpacity};
  }
  ${({ sx }) => sx && sx};
`

const Title = styled.span`
  text-align: center;
  white-space: nowrap;
  opacity: ${({ showLoader }) => (showLoader ? 0 : 1)};
`

const LoaderOuter = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ showLoader }) => (showLoader ? 1 : 0)};
`

const LoaderInner = styled.div`
  width: ${({ size }) => {
    if (size === "small") {
      return `${btnSize.small}px`
    } else if (size === "medium") {
      return `${btnSize.medium}px`
    } else if (size === "large") {
      return `${btnSize.large}px`
    } else {
      return `${btnSize.medium}px`
    }
  }};
`

Button.propTypes = {
  variant: PropTypes.oneOf(["contained", "outlined"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  title: PropTypes.string.isRequired,
  radius: PropTypes.number,
}

Button.defaultProps = {
  variant: "contained",
  color: "primary",
  size: "medium",
  onClick: () => {},
}

export default Button
