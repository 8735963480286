import React, { useMemo } from "react"
import Slider from "rc-slider"
import "rc-slider/assets/index.css"
import "./sliderCss.css"

import Box from "../Box"
import { FormLabel } from "../Typography"

const primaryColor = "#00E000"
const height = 8

const s = {
  dot: {
    r: 16,
    w: 2,
  },
  activeDot: {
    r: 16,
    w: 3,
    c: primaryColor,
  },
  track: {
    h: height,
    c: primaryColor,
  },
  rail: {
    h: height,
  },
  handle: {
    r: 20,
    w: 4,
    c: primaryColor,
  },
}

function SliderQuestion(props) {
  const { disabled, item, input, meta, ...rest } = props

  const settings = useMemo(() => {
    const marks = {}
    for (let i = 0; i < item.data.length; i++) {
      marks[i] = item.data[i]
      marks[i].style = { top: 8 }
    }
    let max = item.data.length - 1
    return {
      min: 0,
      max,
      marks,
    }
  }, [item.data])

  return (
    <Box {...rest}>
      <FormLabel mb={4}>{item?.title}</FormLabel>
      <Box pl={2} pr={4} pb={12} pb_lg={14}>
        <Slider
          min={settings.min}
          max={settings.max}
          marks={settings.marks}
          disabled={disabled}
          value={input.value}
          onChange={input.onChange}
          railStyle={{ height: s.rail.h }}
          disabledRailStyle={{ backgroundColor: "red" }}
          trackStyle={{
            height: s.track.h,
            backgroundColor: s.track.c,
          }}
          disabledTrackStyle={{ backgroundColor: "red" }}
          dotStyle={{
            marginLeft: -`${s.dot.r / 2}`,
            bottom: -`${s.dot.r / 2}`,
            height: s.dot.r,
            width: s.dot.r,
          }}
          activeDotStyle={{
            borderColor: s.activeDot.c,
            marginLeft: -`${s.activeDot.r / 2}`,
            bottom: -`${s.activeDot.r / 2}`,
            height: s.activeDot.r,
            width: s.activeDot.r,
            backgroundColor: "white",
          }}
          handleStyle={{
            bottom: -5,
            height: s.handle.r,
            width: s.handle.r,
            borderWidth: s.handle.w,
            borderStyle: "solid",
            borderColor: primaryColor,
            backgroundColor: "white",
          }}
        />
      </Box>
    </Box>
  )
}

export default SliderQuestion
