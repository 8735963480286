import React from "react"
import styled, { css } from "styled-components"

import { NavigationLabel } from "../Typography"
import Caret from "./Caret"
import NewTabIcon from "./NewTabIcon"

function TabMenuButton(props) {
  const { item, active, onClick = () => {} } = props

  const handleOnClick = React.useCallback(() => {
    onClick(item)
  }, [item, onClick])

  return (
    <TabNavItem>
      <TabNavButtonOuter>
        <TabNavButton
          onClick={handleOnClick}
          active={active}
          highlight={item.highlight}
        >
          <NavigationLabel>{item.title}</NavigationLabel>
          {item.data && (
            <ButtonEndAdornment>
              <Caret active={active} />
            </ButtonEndAdornment>
          )}
          {item.uri && (
            <ButtonEndAdornment>
              <NewTabIcon
                size={16}
                sx={css`
                  opacity: 0.6;
                `}
              />
            </ButtonEndAdornment>
          )}
        </TabNavButton>
      </TabNavButtonOuter>
    </TabNavItem>
  )
}

function TabsMenuMenu(props) {
  const {
    item,
    tabNavPos,
    nestedActive,
    onButton = () => {},
    onNavigate = () => {},
  } = props
  const ACTIVE = item.id === nestedActive

  return (
    <>
      <TabMenuButton item={item} active={ACTIVE} onClick={onButton} />
      <NavNestedToolBar show={ACTIVE} pos={tabNavPos}>
        {item.data.map(subItem => {
          if (subItem.to || subItem.uri) {
            return (
              <TabMenuButton
                key={subItem.id}
                item={subItem}
                onClick={onNavigate}
                highlight={subItem.highlight}
              />
            )
          } else if (subItem.itemSeperator) {
            return (
              <TabNavItem key={subItem.id}>
                <ItemSeperator />
              </TabNavItem>
            )
          } else {
            return null
          }
        })}
      </NavNestedToolBar>
    </>
  )
}

function TabsMenu(props) {
  const {
    menu,
    nestedActive,
    onClose = () => {},
    onButton = () => {},
    onNavigate = () => {},
  } = props
  const tabNavRef = React.useRef()
  const tabRowRef = React.useRef()
  const tabNavPos = React.useRef(null)

  const handleClickAway = React.useCallback(
    e => {
      if (tabRowRef.current && !tabRowRef.current.contains(e.target)) {
        onClose()
      }
    },
    [onClose]
  )

  React.useEffect(() => {
    if (tabNavRef.current) {
      tabNavPos.current = tabNavRef.current.getBoundingClientRect()
    }
    document.addEventListener("mousedown", handleClickAway)
    return () => {
      document.removeEventListener("mousedown", handleClickAway)
    }
  }, [handleClickAway])

  return (
    <TabNav ref={tabNavRef}>
      <TabNavRow ref={tabRowRef}>
        {menu.map(item => {
          if (item.data) {
            return (
              <TabsMenuMenu
                key={item.id}
                item={item}
                tabNavPos={tabNavPos}
                onButton={onButton}
                nestedActive={nestedActive}
                onNavigate={onNavigate}
              />
            )
          } else if (item.to) {
            return (
              <TabMenuButton
                key={item.id}
                item={item}
                onClick={onNavigate}
                highlight={item.highlight}
              />
            )
          } else if (item.itemSeperator) {
            return (
              <TabNavItem key={item.id}>
                <ItemSeperator />
              </TabNavItem>
            )
          } else {
            return null
          }
        })}
      </TabNavRow>
    </TabNav>
  )
}

const TabNav = styled.div`
  display: none;
  visibility: hidden;
  color: inherit;
  flex: 1;
  ${({ theme }) => theme.overrides.NavigationBar.mobileBreakpoint} {
    visibility: visible;
    display: block;
  }
`

const TabNavRow = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  color: inherit;
  height: ${({ theme }) => theme.overrides.NavigationBar.height}px;
`

const TabNavItem = styled.li`
  margin-left: ${({ first }) => (first ? 0 : 42)}px;
  margin-top: 0;
  display: inline-block;
  padding: 0;
  white-space: nowrap;
  list-style: none;
  color: inherit;
`

const TabNavButtonOuter = styled.div`
  background-color: unset;
  padding: 0;
  width: auto;
  box-sizing: border-box;
  color: inherit;
`

const TabNavButton = styled.button.attrs(() => ({
  type: "button",
}))`
  background: none;
  border: 0;
  text-align: unset;
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 2px 8px 1px 8px;
  margin: -2px 8px 0 0px;
  color: ${({ active, theme, highlight }) => {
    if (highlight) {
      return theme.overrides.NavigationBar.attentionColor
    } else if (active) {
      return theme.overrides.NavigationBar.activeColor
    } else {
      return "inherit"
    }
  }};
  @media (hover: hover) {
    & :hover {
      color: ${({ theme, highlight }) => {
        if (highlight) {
          return theme.overrides.NavigationBar.attentionColor
        } else {
          return theme.overrides.NavigationBar.activeColor
        }
      }};
    }
  }
`

const ButtonEndAdornment = styled.span`
  padding-top: 1px;
  margin-left: 12px;
  color: inherit;
`

const NavNestedToolBar = styled.ul`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: ${({ theme }) => theme.overrides.NavigationBar.height}px;
  margin: 0;
  padding: 0;
  position: absolute;
  left: ${({ pos }) => (pos ? pos.left : 0)}px;
  top: ${({ theme }) => theme.overrides.NavigationBar.height}px;
  list-style: none;
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  z-index: 1000;
`

const ItemSeperator = styled.span`
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: inherit;
  border-top: none;
  height: 19px;
`

export default TabsMenu
