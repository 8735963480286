import React, { useCallback, useMemo } from "react"
import { css } from "styled-components"

import ButtonBase from "../ButtonBase"
import Box from "../Box"

function ListItem(props) {
  const { item, onClick } = props

  const handleOnClick = useCallback(() => {
    onClick(item)
  }, [item, onClick])

  const team = useMemo(() => {
    let { name } = item.tags.find(d => d.name.startsWith("#dep_"))
    name = name.split("_")[1]
    return name
  }, [item.tags])

  const location = useMemo(() => {
    let { name } = item.tags.find(d => d.name.startsWith("#loc_"))
    name = name.split("_")[1]
    return name
  }, [item.tags])

  return (
    <Box>
      <ButtonBase onClick={handleOnClick} fullWidth>
        <Box
          pt={5}
          pb={5}
          sx={css`
            position: relative;
            display: flex;
            flex-direction: column;
            text-align: left;
            flex: 1;
            font-size: 16px;
            ${({ theme }) => theme.breakpoint.up.md} {
              flex-direction: row;
            }
          `}
        >
          <Box
            pr={2}
            sx={css`
              font-family: ${({ theme }) => theme.fontFamily.default};
              font-weight: ${({ theme }) => theme.fontWeight.medium};
              width: 100%;
              ${({ theme }) => theme.breakpoint.up.md} {
                width: 50%;
              }
            `}
          >
            {item.title}
          </Box>
          <Box
            pr={2}
            sx={css`
              font-family: ${({ theme }) => theme.fontFamily.default};
              font-weight: ${({ theme }) => theme.fontWeight.normal};
              color: ${({ theme }) => theme.palette.text.secondary};

              width: 100%;
              text-transform: capitalize;
              ${({ theme }) => theme.breakpoint.up.md} {
                width: 25%;
              }
            `}
          >
            {team}
          </Box>
          <Box
            pr={2}
            sx={css`
              font-family: ${({ theme }) => theme.fontFamily.default};
              font-weight: ${({ theme }) => theme.fontWeight.normal};
              color: ${({ theme }) => theme.palette.text.secondary};
              width: 100%;
              text-transform: capitalize;
              ${({ theme }) => theme.breakpoint.up.md} {
                width: 25%;
              }
            `}
          >
            {location}
          </Box>
          <Box
            sx={css`
              position: absolute;
              top: 50%;
              right: 0px;
              transform: translate(0px, -50%);
            `}
          >
            ➞
          </Box>
        </Box>
      </ButtonBase>
    </Box>
  )
}

ListItem.defaultProps = {
  onClick: () => {},
}

export default ListItem
