const SITE_URL = process.env.SITE_URL || `https://123abc.com`

const siteMetadata = {
  title: "123abc.com | Human Cloud Network",
  tagline: "At 123abc, we’re reimagining talent development",
  description:
    "As the first open all-in-one talent network for people and businesses, 123abc brings people, talent developers, and businesses together in an interactive and highly motivating online experience that feels like a safe small community, but with a global reach. Whether you're looking for a career swift, re-skill for a different title, or want to remain sharp on a skill - 123abc will assist and connect you to resources to get you directly to the goal. We’re spearheading the innovative technology that is enabling people to grow faster into more productive roles like never before.",
  brandBanner: `${SITE_URL}/assets/brand-banner.png`,
  socialLinks: {
    linkedin: `https://www.linkedin.com/company/123abc-com/`,
    facebook: `https://www.facebook.com/123abccom`,
    twitter: `https://twitter.com/123abcnet`,
    youtube: `#`,
    github: `#`,
  },
  socialHandles: {
    linkedin: `123abc-com`,
    facebook: `123abccom`,
    twitter: `@123abcnet`,
    youtube: ``,
    github: ``,
  },
  siteUrl: SITE_URL,
}

export default siteMetadata
