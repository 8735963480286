import React from "react"
import styled from "styled-components"

import { marginCSS } from "../CSS"

import { P } from "../Typography"

function Badge(props) {
  const { text, ...rest } = props
  return (
    <Root {...rest}>
      <Inner>
        <Text>{text}</Text>
      </Inner>
    </Root>
  )
}

const Root = styled.div`
  display: inline-block;
  background-color: ${({ theme }) => theme.palette.purple.main};
  border-radius: 4px;
  ${marginCSS};
`

const Inner = styled.div`
  padding: 4px;
`

const Text = styled(P)`
  font-size: 12px;
  font-weight: 700;
  color: #eaafaf;
`

export default Badge
