import React from "react"
import styled, { css } from "styled-components"

import TextLink from "../TextLink"
import SocialLinks from "../SocialLinks"
// import AppLinks from "../AppLinks"
import Container from "../Container"

import Menu from "./Menu"
import LocaleIndicator from "./LocaleIndicator"

import { menu } from "../../navigation/menu"
import { legal } from "../../navigation/legal"
import NewsletterSignup from "../NewsletterSignup"

import { social } from "../../navigation/social"

function Footer() {
  const isSocialActive = Boolean(social.find(item => item.show))

  return (
    <Wrapper>
      <FooterOuter>
        <Container>
          <Section border fontSize="sm">
            <Section1>
              <Menu menu={menu} />
              <NewsletterSignupWrapper id="newsletter_footer">
                <NewsletterSignup compactBreakpoint="lg" />
              </NewsletterSignupWrapper>
            </Section1>
          </Section>
          <Section border fontSize="sm">
            <Grid container>
              <Grid item>
                <LocaleIndicator lang="English" place="" />
              </Grid>
              {isSocialActive && (
                <Grid item>
                  <SocialLinks social={social} />
                </Grid>
              )}
            </Grid>
          </Section>
          <Section fontSize="xs">
            <Grid container>
              <Grid item>
                {legal.map((l, i) => (
                  <TextLink
                    key={l.id}
                    to={l.url}
                    mr={i === legal.length - 1 ? 0 : 8}
                    mx={2}
                  >
                    {l.title}
                  </TextLink>
                ))}
              </Grid>
              <Grid item>
                <span>©2021 123abc.com</span>
              </Grid>
            </Grid>
          </Section>
        </Container>
      </FooterOuter>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`

const FooterOuter = styled.footer`
  background-color: ${({ theme }) => theme.palette.black.main};
  border: 0;
  margin: 0;
  padding-top: ${({ theme }) => theme.spacing[4]};
  padding-bottom: ${({ theme }) => theme.spacing[4]};
  width: 100%;
  color: ${({ theme }) => theme.palette.black.contrastText};
`

const Section = styled.section`
  ${({ fontSize, theme }) =>
    fontSize && `font-size: ${theme.fontSize[fontSize]}`};
  font-family: ${({ theme }) => theme.fontFamily.default};
  padding-top: ${({ theme }) => theme.spacing[6]};
  padding-bottom: ${({ theme }) => theme.spacing[6]};
  border-bottom-width: ${({ border }) => (border ? "1px" : 0)};
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) => theme.palette.black.contrastBg};
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ theme }) => theme.breakpoint.up.sm} {
  }
  ${({ theme }) => theme.breakpoint.up.md} {
  }
  ${({ theme }) => theme.breakpoint.up.lg} {
  }
  ${({ theme }) => theme.breakpoint.up.xl} {
  }
`

const Section1 = styled.div`
  display: flex;
  flex-direction: column-reverse;
  & > #newsletter_footer {
    margin-bottom: ${({ theme }) => theme.spacing[8]};
    min-width: 300px;
    max-width: 750px;
  }
  ${({ theme }) => theme.breakpoint.up.sm} {
  }
  ${({ theme }) => theme.breakpoint.up.md} {
  }
  ${({ theme }) => theme.breakpoint.up.lg} {
    flex-direction: row;
    & > #newsletter_footer {
      margin-bottom: ${({ theme }) => theme.spacing[0]};
      min-width: 470px;
      max-width: 490px;
      margin-left: auto;
    }
  }
  ${({ theme }) => theme.breakpoint.up.xl} {
  }
`

// Grid
const vSpacing = 8

const gridContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap-reverse;
  margin-bottom: -${({ theme }) => theme.spacing[vSpacing]};

  ${({ theme }) => theme.breakpoint.up.sm} {
  }
  ${({ theme }) => theme.breakpoint.up.md} {
  }
  ${({ theme }) => theme.breakpoint.up.lg} {
  }
  ${({ theme }) => theme.breakpoint.up.xl} {
  }
`

const gridItem = css`
  flex-basis: 100%;
  white-space: nowrap;
  margin-bottom: ${({ theme }) => theme.spacing[vSpacing]};
  ${({ theme }) => theme.breakpoint.up.sm} {
  }
  ${({ theme }) => theme.breakpoint.up.md} {
    flex-basis: 0;
    flex-shrink: 0;
  }
  ${({ theme }) => theme.breakpoint.up.lg} {
  }
  ${({ theme }) => theme.breakpoint.up.xl} {
  }
`

const Grid = styled.div`
  display: flex;
  ${({ container }) => container && gridContainer};
  ${({ item }) => item && gridItem};
`

const NewsletterSignupWrapper = styled.section``

export default Footer
