import { SET_PRIVACY_AGREE } from "./constants"

const initialState = {
  privAgree: false,
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_PRIVACY_AGREE:
      return {
        ...state,
        privAgree: payload.privAgree,
      }
    default:
      return state
  }
}
