import React from "react"
// import styled from "styled-components"
import LottieView from "react-lottie"
import { delay } from "../../utils/common"

import animationData from "./loading-animation.json"

// const getThemedVariantColor = ({ theme, color, variant }) => {
//   switch (variant) {
//     case "outlined":
//       return theme.palette[color].main
//     case "rounded":
//       return theme.palette[color].contrastText
//     default:
//       return theme.palette[color].contrastText
//   }
// }

// const StyledLottieView = styled(LottieView).attrs(props => ({
//     colorFilters: [
//       {
//         keypath: "Camada de forma 1",
//         color: getThemedVariantColor(props),
//       },
//       {
//         keypath: "Camada de forma 2",
//         color: getThemedVariantColor(props),
//       },
//       {
//         keypath: "Camada de forma 3",
//         color: getThemedVariantColor(props),
//       },
//     ],
// }))`
//   height: 32px;
//   opacity: ${props => (props.show ? 1 : 0)};
//   & div > svg > {
//     width: auto;
//   }
// `

function Loading({ loadingDelay, loaderOnChange = () => {} }) {
  const mountedRef = React.useRef()
  const mountedRef2 = React.useRef()
  const [show, setShow] = React.useState(false)

  React.useEffect(() => {
    async function startDelay() {
      mountedRef.current = true
      await delay(loadingDelay)
      if (mountedRef.current) {
        setShow(true)
        await delay(300)
      }
    }
    startDelay()
    return () => {
      mountedRef.current = false
    }
  }, [loadingDelay])

  React.useEffect(() => {
    if (!mountedRef2.current) {
      mountedRef2.current = true
      return
    }
    loaderOnChange({ show: show })
    return () => {
      loaderOnChange({ show: false })
    }
  }, [loaderOnChange, show])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return <LottieView options={defaultOptions} style={{ display: "flex" }} />
}

Loading.defaultProps = {
  loadingDelay: 1800,
  loaderOnChange: () => {},
}

export default Loading
