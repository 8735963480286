import styled, { css } from "styled-components"

import { marginCSS, widthCSS } from "../CSS"

/**
 *  NavgationBar
 *  Color is set on btnElement
 */
export const NavigationLabel = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.headings};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.overrides.MenuLink.fontSize};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  line-height: ${({ theme }) => theme.lineHeight.normal};
  text-decoration: unset;
  margin: 0;
  color: inherit;
  ${({ sx }) => sx && sx}
`

export const Title = styled.div.attrs(({ as = "h1" }) => ({
  as,
}))`
  font-family: ${({ theme }) => theme.fontFamily.headings};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: 32px;
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  text-decoration: unset;
  margin: 0;
  color: inherit;
  ${({ theme }) => theme.breakpoint.up.md} {
    font-size: 52px;
  }
  ${marginCSS};
  ${widthCSS};
  ${({ sx }) => sx && sx}
`

export const Paragraph = styled.div.attrs(({ as = "span" }) => ({
  as,
}))`
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  font-size: 15px;
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  text-decoration: unset;
  margin: 0;
  display: block;
  color: inherit;
  ${({ theme }) => theme.breakpoint.up.md} {
    font-size: 18px;
  }
  ${marginCSS};
  ${widthCSS};
  ${({ sx }) => sx && sx}
`

const titleBaseCss = css`
  font-family: ${({ theme }) => theme.fontFamily.headings};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  text-decoration: unset;
  margin: 0;
  color: inherit;
`

// BASE STYLES
export const H1 = styled.div.attrs(({ as = "h1" }) => ({
  as,
}))`
  ${titleBaseCss}
  font-size: 46px;
  line-height: 50px;
  ${({ theme }) => theme.breakpoint.up.md} {
    font-size: 60px;
    line-height: 64px;
  }
  ${({ theme }) => theme.breakpoint.up.lg} {
    font-size: 82px;
    line-height: 86px;
  }
  ${marginCSS}
  ${widthCSS};
  ${({ sx }) => sx && sx}
`

export const H2 = styled.div.attrs(({ as = "h2" }) => ({
  as,
}))`
  ${titleBaseCss}
  font-size: 34px;
  line-height: 38px;
  ${({ theme }) => theme.breakpoint.up.md} {
    font-size: 48px;
    line-height: 52px;
  }
  ${({ theme }) => theme.breakpoint.up.lg} {
    font-size: 64px;
    line-height: 68px;
  }
  ${marginCSS}
  ${widthCSS};
  ${({ sx }) => sx && sx}
`

export const H3 = styled.div.attrs(({ as = "h3" }) => ({
  as,
}))`
  ${titleBaseCss}
  font-size: 26px;
  line-height: 1.17;
  font-weight: 400;
  ${({ theme }) => theme.breakpoint.up.md} {
    font-size: 30px;
  }
  ${({ theme }) => theme.breakpoint.up.lg} {
    font-size: 34px;
  }
  ${marginCSS}
  ${widthCSS};
  ${({ sx }) => sx && sx}
`

export const H4 = styled.div.attrs(({ as = "h3" }) => ({
  as,
}))`
  font-size: 20px;
  line-height: 1.17;
  font-weight: 500;
  ${({ theme }) => theme.breakpoint.up.md} {
    font-size: 24px;
  }
  ${({ theme }) => theme.breakpoint.up.lg} {
    font-size: 28px;
  }
  ${marginCSS}
  ${widthCSS};
  ${({ sx }) => sx && sx}
`

export const SubTitle = styled.div.attrs(({ as = "p" }) => ({
  as,
}))`
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  font-size: 18px;
  text-decoration: unset;
  margin: 0;
  display: block;
  color: inherit;
  ${({ theme }) => theme.breakpoint.up.md} {
    font-size: 22px;
  }
  ${marginCSS}
  ${widthCSS};
  ${({ sx }) => sx && sx}
`

export const P = styled.div.attrs(({ as = "p" }) => ({
  as,
}))`
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  font-size: 18px;
  text-decoration: unset;
  margin: 0;
  display: block;
  color: inherit;
  ${marginCSS}
  ${widthCSS};
  ${({ sx }) => sx && sx}
`

export const FormLabel = styled.div.attrs(({ as = "span" }) => ({
  as,
}))`
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  letter-spacing: ${({ theme }) => theme.letterSpacing.normal};
  font-size: 18px;
  text-decoration: unset;
  margin: 0;
  display: block;
  color: ${({ theme }) => theme.palette.text.secondary};
  ${({ theme }) => theme.breakpoint.up.md} {
    font-size: 20px;
  }
  ${marginCSS}
  ${widthCSS};
  ${({ sx }) => sx && sx}
`
