import React from "react"
import styled, { css } from "styled-components"

import Box from "../Box"
import ErrorForm from "../ErrorForm"

function Input(props) {
  const { sx, bgColor, input, meta, variant, ...rest } = props
  return (
    <>
      <InputWrapper variant={variant} sx={sx}>
        <StyledInput bgColor={bgColor} {...input} {...rest} />
      </InputWrapper>
      {meta.error && meta.touched && <ErrorForm error={meta.error} />}
    </>
  )
}

const outlinedCss = css`
  border-width: 1px;
  border-color: #000;
  border-style: solid;
`

const containedCss = css`
  background: rgb(30, 33, 36);
`

const InputWrapper = styled.div`
  ${({ variant }) => {
    if (variant === "outlined") {
      return outlinedCss
    }
    if (variant === "contained") {
      return containedCss
    }
  }};
  align-items: center;

  border-radius: 32px;
  box-sizing: border-box;
  cursor: text;
  display: flex;
  height: 64px;
  overflow: hidden;
  & :focus {
    outline: auto 1px;
  }
  ${({ sx }) => sx && sx}
`

const StyledInput = styled.input`
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 28px;
  letter-spacing: -0.25px;
  text-decoration: unset;
  border: none;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  background-color: transparent;
  height: 100%;
  flex-grow: 1;
  min-width: 0;
  padding: 0;
  padding-left: 36px;
  & :focus {
    outline: none;
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    -webkit-box-shadow: ${({ theme, bgColor }) =>
      `0 0 0px 1000px ${theme.palette[bgColor]?.background || bgColor} inset`};
    box-shadow: ${({ theme, bgColor }) =>
      `0 0 0px 1000px ${theme.palette[bgColor]?.background || bgColor} inset`};
    transition: background-color 5000s ease-in-out 0s;
  }
`

Input.defaultProps = {
  variant: "contained",
  bgColor: "#fff",
}

export default Input
