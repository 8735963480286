import React from "react"
import styled from "styled-components"

function MenuButton(props) {
  const { open, onClick } = props
  return (
    <Wrapper>
      <ButtonBase onClick={onClick}>
        <SvgAnimated open={open} fill="rgb(0, 0, 0)" height="30" width="30">
          <g className="topBurgerBar">
            <rect fill="transparent" height="30" width="30" x="0" y="0"></rect>
            <rect height="3" width="30" x="0" y="7"></rect>
          </g>
          <g className="bottomBurgerBar">
            <rect fill="transparent" height="30" width="30" x="0" y="0"></rect>
            <rect height="3" width="30" x="0" y="20"></rect>
          </g>
        </SvgAnimated>
      </ButtonBase>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: block;
  visibility: visible;
  margin-left: auto;
  ${({ theme }) => theme.overrides.NavigationBar.mobileBreakpoint} {
    visibility: hidden;
    display: none;
  }
`

const ButtonBase = styled.button.attrs(() => ({
  type: "button",
}))`
  background: none;
  border: 0;
  padding: 0;
  text-align: unset;
  color: unset;
  display: flex;
  padding: 8px;
  margin-right: -8px;
`

const SvgAnimated = styled.svg`
  & .topBurgerBar {
    transform-origin: center center;
    transition: transform 300ms;
    ${({ open }) =>
      open && "transform: rotate(-45deg) translate3d(0px, 6.5px, 0px)"};
  }
  & .bottomBurgerBar {
    transform-origin: center center;
    transition: transform 300ms;
    ${({ open }) =>
      open && "transform: rotate(45deg) translate3d(0px, -6.5px, 0px)"};
  }
`

export default MenuButton
