import React, { useCallback, useMemo } from "react"
import { css } from "styled-components"
import { graphql, navigate } from "gatsby"
import Helmet from "react-helmet"

import ButtonBase from "../components/ButtonBase"
import Box from "../components/Box"
import Section from "../components/Section"
import JobPost from "../components/JobPost"
import JobIntroduce from "../components/JobIntroduce"

import { CAREER } from "../navigation/constants"

import Layout from "../layouts/Primary"

function JobPostTemplate(props) {
  const { data } = props

  const handleGoBack = useCallback(() => {
    navigate(CAREER)
  }, [])

  const team = useMemo(() => {
    let { name } = data.post.tags.find(d => d.name.startsWith("#dep_"))
    name = name.split("_")[1]
    return name
  }, [data.post.tags])

  const location = useMemo(() => {
    let { name } = data.post.tags.find(d => d.name.startsWith("#loc_"))
    name = name.split("_")[1]
    return name
  }, [data.post.tags])

  const employmentType = useMemo(() => {
    let tag = data.post.tags.find(d => d.name.startsWith("#emp_"))
    if (tag) {
      tag = tag.name.split("_")[1]
      return tag
    }
  }, [data.post.tags])

  const seoTitle = useMemo(() => {
    let title = data.post.title
    if (location) {
      title = `${title} | ${location.toUpperCase()}`
    }
    if (employmentType) {
      title = `${title} | ${employmentType.toUpperCase()}`
    }
    return title
  }, [data.post.title, location, employmentType])

  return (
    <Layout
      title={seoTitle}
      url={`${CAREER}/p/${data.post.slug}`}
      description={data.post.plaintext}
      canonicalURL={`${CAREER}/p/${data.post.slug}`}
    >
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "JobPosting",
            title: data.post.title,
            description: data.post.html,
            identifier: {
              "@type": "PropertyValue",
              name: "123abc.com",
              value: data.post.slug,
            },
            datePosted: "2021-07-05",
            jobLocationType: "TELECOMMUTE",
            employmentType: "FULL_TIME",
            hiringOrganization: {
              "@type": "Organization",
              name: "123abc.com",
              sameAs: "https://123abc.com",
              logo: "https://123abc.com/assets/icon.png",
            },
          })}
        </script>
      </Helmet>
      <Box
        sx={css`
          position: relative;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
        `}
      >
        <ButtonBase
          sx={css`
            position: absolute;
            top: ${({ theme }) => theme.spacing[6]};
            left: ${({ theme }) => theme.spacing[4]};
            ${({ theme }) => theme.breakpoint.up.sm} {
              left: ${({ theme }) => theme.spacing[6]};
            }
          `}
          onClick={handleGoBack}
        >
          ⬅︎ Go back
        </ButtonBase>
        <Section px={4}>
          <JobPost post={data.post} maxWidth="md" />
        </Section>
        <Section
          px={4}
          sx={css`
            background-color: ${({ theme }) => theme.palette.green.background};
          `}
        >
          <JobIntroduce
            post={data.post}
            id={data.post.slug}
            title={
              team
                ? `Introduce yourself to the ${team} team`
                : "Introduce yourself to this Job"
            }
            maxWidth="lg"
          />
        </Section>
      </Box>
    </Layout>
  )
}

export default JobPostTemplate

export const postQuery = graphql`
  query($id: String!) {
    post: ghostPost(id: { eq: $id }) {
      slug
      id
      title
      excerpt
      tags {
        name
      }
      plaintext
      html
    }
  }
`
