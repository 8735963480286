import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

import LanguageIcon from "../Icons/Language"
import LocationIcon from "../Icons/Place"

function LocaleIndicator(props) {
  const { lang, place } = props
  if (!lang && !place) return null
  return (
    <Grid container>
      {lang && (
        <Grid item>
          <LanguageIcon fontSize={18} />
          <span>{lang}</span>
        </Grid>
      )}
      {place && (
        <Grid item>
          <LocationIcon fontSize={18} />
          <span>{place}</span>
        </Grid>
      )}
    </Grid>
  )
}

const vSpacing = 3

const containerCss = css`
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: -${({ theme }) => theme.spacing[vSpacing]};
  ${({ theme }) => theme.breakpoint.up.md} {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
`

const itemCss = css`
  white-space: nowrap;
  margin-bottom: ${({ theme }) => theme.spacing[vSpacing]};
  margin-right: ${({ theme }) => theme.spacing[8]};
  & > span {
    margin-left: ${({ theme }) => theme.spacing[2]};
  }
`

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  ${({ container }) => container && containerCss}
  ${({ item }) => item && itemCss}
`

LocaleIndicator.propTypes = {
  lang: PropTypes.string,
  place: PropTypes.string,
}

LocaleIndicator.defaultProps = {
  lang: "",
  place: "",
}

export default LocaleIndicator
