import styled from "styled-components"
import { paddingCSS } from "../CSS"

export default styled.section`
  padding-top: 4rem;
  padding-bottom: 4rem;
  ${({ bgColor }) => bgColor && `background-color: ${bgColor}`};
  ${({ color }) => color && `color: ${color}`};
  ${({ theme }) => theme.breakpoint.up.md} {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  ${paddingCSS};
  ${({ sx }) => sx && sx};
`
