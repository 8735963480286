import { css } from "styled-components"

export const marginCSS = css`
  ${({ mt, theme }) => mt && `margin-top: ${theme.spacing[mt]}`};
  ${({ mb, theme }) => mb && `margin-bottom: ${theme.spacing[mb]}`};
  ${({ mr, theme }) => mr && `margin-right: ${theme.spacing[mr]}`};
  ${({ ml, theme }) => ml && `margin-left: ${theme.spacing[ml]}`};
  ${({ theme }) => theme.breakpoint.up.sm} {
    ${({ mt_sm, theme }) => mt_sm && `margin-top: ${theme.spacing[mt_sm]}`};
    ${({ mb_sm, theme }) => mb_sm && `margin-bottom: ${theme.spacing[mb_sm]}`};
    ${({ mr_sm, theme }) => mr_sm && `margin-right: ${theme.spacing[mr_sm]}`};
    ${({ ml_sm, theme }) => ml_sm && `margin-left: ${theme.spacing[ml_sm]}`};
  }
  ${({ theme }) => theme.breakpoint.up.md} {
    ${({ mt_md, theme }) => mt_md && `margin-top: ${theme.spacing[mt_md]}`};
    ${({ mb_md, theme }) => mb_md && `margin-bottom: ${theme.spacing[mb_md]}`};
    ${({ mr_md, theme }) => mr_md && `margin-right: ${theme.spacing[mr_md]}`};
    ${({ ml_md, theme }) => ml_md && `margin-left: ${theme.spacing[ml_md]}`};
  }
  ${({ theme }) => theme.breakpoint.up.lg} {
    ${({ mt_lg, theme }) => mt_lg && `margin-top: ${theme.spacing[mt_lg]}`};
    ${({ mb_lg, theme }) => mb_lg && `margin-bottom: ${theme.spacing[mb_lg]}`};
    ${({ mr_lg, theme }) => mr_lg && `margin-right: ${theme.spacing[mr_lg]}`};
    ${({ ml_lg, theme }) => ml_lg && `margin-left: ${theme.spacing[ml_lg]}`};
  }
  ${({ theme }) => theme.breakpoint.up.xl} {
    ${({ mt_xl, theme }) => mt_xl && `margin-top: ${theme.spacing[mt_xl]}`};
    ${({ mb_xl, theme }) => mb_xl && `margin-bottom: ${theme.spacing[mb_xl]}`};
    ${({ mr_xl, theme }) => mr_xl && `margin-right: ${theme.spacing[mr_xl]}`};
    ${({ ml_xl, theme }) => ml_xl && `margin-left: ${theme.spacing[ml_xl]}`};
  }
  ${({ center }) => center && `margin-left: auto; margin-right: auto;`};
`

export const paddingCSS = css`
  ${({ pt, py, theme }) =>
    (py || pt) && `padding-top: ${theme.spacing[py || pt]}`};
  ${({ pb, py, theme }) =>
    (py || pb) && `padding-bottom: ${theme.spacing[py || pb]}`};
  ${({ pr, px, theme }) =>
    (px || pr) && `padding-right: ${theme.spacing[px || pr]}`};
  ${({ pl, px, theme }) =>
    (px || pl) && `padding-left: ${theme.spacing[px || pl]}`};
  ${({ theme }) => theme.breakpoint.up.sm} {
    ${({ pt_sm, theme }) => pt_sm && `padding-top: ${theme.spacing[pt_sm]}`};
    ${({ pb_sm, theme }) => pb_sm && `padding-bottom: ${theme.spacing[pb_sm]}`};
    ${({ pr_sm, theme }) => pr_sm && `padding-right: ${theme.spacing[pr_sm]}`};
    ${({ pl_sm, theme }) => pl_sm && `padding-left: ${theme.spacing[pl_sm]}`};
  }
  ${({ theme }) => theme.breakpoint.up.md} {
    ${({ pt_md, theme }) => pt_md && `padding-top: ${theme.spacing[pt_md]}`};
    ${({ pb_md, theme }) => pb_md && `padding-bottom: ${theme.spacing[pb_md]}`};
    ${({ pr_md, theme }) => pr_md && `padding-right: ${theme.spacing[pr_md]}`};
    ${({ pl_md, theme }) => pl_md && `padding-left: ${theme.spacing[pl_md]}`};
  }
  ${({ theme }) => theme.breakpoint.up.lg} {
    ${({ pt_lg, theme }) => pt_lg && `padding-top: ${theme.spacing[pt_lg]}`};
    ${({ pb_lg, theme }) => pb_lg && `padding-bottom: ${theme.spacing[pb_lg]}`};
    ${({ pr_lg, theme }) => pr_lg && `padding-right: ${theme.spacing[pr_lg]}`};
    ${({ pl_lg, theme }) => pl_lg && `padding-left: ${theme.spacing[pl_lg]}`};
  }
  ${({ theme }) => theme.breakpoint.up.xl} {
    ${({ pt_xl, theme }) => pt_xl && `padding-top: ${theme.spacing[pt_xl]}`};
    ${({ pb_xl, theme }) => pb_xl && `padding-bottom: ${theme.spacing[pb_xl]}`};
    ${({ pr_xl, theme }) => pr_xl && `padding-right: ${theme.spacing[pr_xl]}`};
    ${({ pl_xl, theme }) => pl_xl && `padding-left: ${theme.spacing[pl_xl]}`};
  }
`

export const widthCSS = css`
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px`};
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px`};
  ${({ theme }) => theme.breakpoint.up.sm} {
    ${({ maxWidth_sm }) => maxWidth_sm && `max-width: ${maxWidth_sm}px`};
    ${({ minWidth_sm }) => minWidth_sm && `min-width: ${minWidth_sm}px`};
  }
  ${({ theme }) => theme.breakpoint.up.md} {
    ${({ maxWidth_md }) => maxWidth_md && `max-width: ${maxWidth_md}px`};
    ${({ minWidth_md }) => minWidth_md && `min-width: ${minWidth_md}px`};
  }
  ${({ theme }) => theme.breakpoint.up.lg} {
    ${({ maxWidth_lg }) => maxWidth_lg && `max-width: ${maxWidth_lg}px`};
    ${({ minWidth_lg }) => minWidth_lg && `min-width: ${minWidth_lg}px`};
  }
  ${({ theme }) => theme.breakpoint.up.xl} {
    ${({ maxWidth_xl }) => maxWidth_xl && `max-width: ${maxWidth_xl}px`};
    ${({ minWidth_xl }) => minWidth_xl && `min-width: ${minWidth_xl}px`};
  }
`
