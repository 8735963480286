import styled, { css } from "styled-components"
import { Link } from "gatsby"

const padding = css`
  ${({ theme, pt, px }) =>
    (px || pt) && `padding-top: ${theme.spacing[px || pt]}`};
  ${({ theme, pb, px }) =>
    (px || pb) && `padding-bottom: ${theme.spacing[px || pb]}`};
  ${({ theme, pl, py }) =>
    (py || pl) && `padding-left: ${theme.spacing[py || pl]}`};
  ${({ theme, pr, py }) =>
    (py || pr) && `padding-right: ${theme.spacing[py || pr]}`};
`

const margin = css`
  ${({ theme, mt, mx }) =>
    (mx || mt) && `margin-top: ${theme.spacing[mx || mt]}`};
  ${({ theme, mb, mx }) =>
    (mx || mb) && `margin-bottom: ${theme.spacing[mx || mb]}`};
  ${({ theme, ml, my }) =>
    (my || ml) && `margin-left: ${theme.spacing[my || ml]}`};
  ${({ theme, mr, my }) =>
    (my || mr) && `margin-right: ${theme.spacing[my || mr]}`};
`

export default styled(Link)`
  text-decoration: none;
  color: inherit;
  ${padding}
  ${margin}
  @media (hover: hover) {
    & :hover {
      opacity: 0.72;
    }
  }
  & :visited {
    color: unset;
  }
`
