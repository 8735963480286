import React from "react"
import styled from "styled-components"

import LinkedinIcon from "../Icons/LinkedIn"
import TwitterIcon from "../Icons/Twitter"
import GitHubIcon from "../Icons/GitHub"
import FacebookIcon from "../Icons/Facebook"
import YouTubeIcon from "../Icons/YouTube"

import IconButton from "../IconButton"

function SocialLinks(props) {
  const { size = "3xl", social } = props
  const handleOnclick = React.useCallback(item => {
    if (item && item.url) {
      window.open(item.url, "_blank")
    }
  }, [])

  const iconSettings = {
    size,
  }

  const iconMap = {
    twitter: <TwitterIcon {...iconSettings} />,
    linkedin: <LinkedinIcon {...iconSettings} />,
    github: <GitHubIcon {...iconSettings} />,
    facebook: <FacebookIcon {...iconSettings} />,
    youtube: <YouTubeIcon {...iconSettings} />,
  }

  return (
    <LinkRow>
      {social.map((s, i) => {
        if (s.show) {
          const icon = s.id.split("-")[0].toLocaleLowerCase()
          return (
            <ButtonOuter key={s.id} last={i === social.length - 1}>
              <IconButton item={s} onClick={handleOnclick} color="inherit">
                {iconMap[icon]}
              </IconButton>
            </ButtonOuter>
          )
        }
        return null
      })}
    </LinkRow>
  )
}

const LinkRow = styled.div`
  /* transform: translateX(-10px); */
  display: flex;
  flex-wrap: nowrap;
  color: inherit;
`

const ButtonOuter = styled.div`
  display: block;
  margin-right: ${({ theme, last }) => theme.spacing[last ? 0 : 4]};
  color: inherit;
`

export default SocialLinks
