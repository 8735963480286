import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

import Button from "../Button"

import BoostLogo from "./BoostLogo"

import sendgrid from "../../service/sendgrid"
import { validateEmail } from "../../utils/form"

function NewsletterSignup(props) {
  const { compact, compactBreakpoint } = props
  const [subcribed, setSubscribed] = React.useState(false)
  const [processing, setProcessing] = React.useState(false)
  const [error, setError] = React.useState("")
  const [email, setEmail] = React.useState("")

  const handleInputChange = React.useCallback(
    event => {
      if (error) {
        setError("")
      }
      const target = event.target
      setEmail(target.value)
    },
    [error]
  )

  const handleSubscribe = React.useCallback(async () => {
    try {
      setProcessing(true)
      if (error) {
        setError("")
      }
      await validateEmail(email)
      await sendgrid.doAddEmailToBoostList(email)
      setSubscribed(true)
    } catch (err) {
      // TODO: only set for user meaningfull messages and pass the rest to log.
      setError(err.message)
    } finally {
      setProcessing(false)
    }
  }, [email, error])

  return (
    <>
      <BoostLogo />
      <Hero>
        <span>
          Need a Boost! Receive blogs about DeTa and the Human Cloud Network
          straight to your Inbox.
        </span>
      </Hero>
      {subcribed ? (
        <SubscribedWrapper>
          <SubscribedMessage>
            You are subscribed! Best decision you’ve ever made!
          </SubscribedMessage>
        </SubscribedWrapper>
      ) : (
        <Form>
          <FormWrapper>
            <Input
              type="email"
              placeholder="name@email.com"
              required
              value={email}
              onChange={handleInputChange}
              disabled={processing}
            />
            {/* {!compact && ( */}
            <BtnWrapper
              compactBreakpoint={compactBreakpoint}
              compact={compact}
              inline
            >
              <Button
                onClick={handleSubscribe}
                disabled={processing}
                processing={processing}
                title="Subscribe"
              />
            </BtnWrapper>
            {/* )} */}
          </FormWrapper>
          {error && (
            <ErrorWrapper>
              <ErrorMessage>{error}</ErrorMessage>
            </ErrorWrapper>
          )}
          {/* {compact && ( */}
          <BtnWrapper compactBreakpoint={compactBreakpoint} compact={compact}>
            <Button
              onClick={handleSubscribe}
              disabled={processing}
              processing={processing}
              fullWidth
              mt={3}
              title="Subscribe"
            />
          </BtnWrapper>
          {/* )} */}
        </Form>
      )}
    </>
  )
}

const Hero = styled.div`
  margin: ${({ theme }) => theme.spacing[4]} 0;
  font-family: ${({ theme }) => theme.fontFamily.headings};
  font-size: ${({ theme }) => theme.fontSize["md"]};
`

const Form = styled.form`
  position: relative;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  visibility: visible;
`

const FormWrapper = styled.div`
  align-items: center;
  background: rgb(30, 33, 36);
  border-radius: 32px;
  box-sizing: border-box;
  cursor: text;
  display: flex;
  height: 64px;
  padding-right: 12px;
  & :focus {
    outline: auto 1px;
  }
`

const Input = styled.input`
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 28px;
  letter-spacing: -0.25px;
  text-decoration: unset;
  border: none;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  background-color: transparent;
  height: 100%;
  flex-grow: 1;
  margin-right: 12px;
  min-width: 0;
  padding: 0;
  padding-left: 36px;
  & :focus {
    outline: none;
  }
`

// TOGGLE TO/FROM COMPACT
const toggleBreakPointCss = css`
  visibility: ${({ inline }) => (inline ? "hidden" : "visible")};
  display: ${({ inline }) => (inline ? "none" : "block")};
  ${({ theme, compactBreakpoint }) => theme.breakpoint.up[compactBreakpoint]} {
    visibility: ${({ inline }) => (inline ? "visible" : "hidden")};
    display: ${({ inline }) => (inline ? "block" : "none")};
  }
`

const togglePropCss = css`
  visibility: ${({ inline, compact }) => {
    if (!compact && inline) {
      return "visible"
    } else if (!compact && !inline) {
      return "hidden"
    } else if (compact && inline) {
      return "hidden"
    } else {
      return "visible"
    }
  }};
  display: ${({ inline, compact }) => {
    if (!compact && inline) {
      return "block"
    } else if (!compact && !inline) {
      return "none"
    } else if (compact && inline) {
      return "none"
    } else {
      return "block"
    }
  }};
`

const BtnWrapper = styled.div`
  flex-shrink: 0;
  ${({ compactBreakpoint }) => !compactBreakpoint && togglePropCss}
  ${({ compactBreakpoint }) => compactBreakpoint && toggleBreakPointCss}
`

const SubscribedWrapper = styled.div`
  padding: 20px 0px;
`

const SubscribedMessage = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.fontSize["xs"]};
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: -0.1px;
  text-decoration: unset;
  margin: 0px;
  color: rgb(0, 200, 5);
`

const ErrorWrapper = styled.div`
  padding: 8px 0px;
`

const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.palette.error.main};
`

NewsletterSignup.propTypes = {
  compact: PropTypes.bool,
  /**
   * compactBreakpoint takes presens over compact
   */
  compactBreakpoint: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
}

NewsletterSignup.defaultProps = {
  compact: false,
  compactBreakpoint: "",
}

export default NewsletterSignup
