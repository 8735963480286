import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"

const BlogPost = ({ post, toTitlePath }) => {
  // const createMarkup = useMemo(() => {
  //   return {
  //     __html: DOMPurify.sanitize(post.html),
  //   }
  // }, [post.html])

  return (
    <OuterSection>
      <InnerSection>
        {post.feature_image ? (
          <FeaturedImage src={post.feature_image} alt={post.title} />
        ) : null}
        <Header>
          <TimeWrapperOuter>
            <div>
              <time>{post.published_at}</time>
            </div>
          </TimeWrapperOuter>
          <Title>
            {toTitlePath ? (
              <Link to={toTitlePath}>{post.title}</Link>
            ) : (
              post.title
            )}
          </Title>
        </Header>

        <Content dangerouslySetInnerHTML={{ __html: post.html }} />
      </InnerSection>
    </OuterSection>
  )
}

// CSS injected into Content
const common = css`
  font-family: ${({ theme }) => theme.fontFamily.default};
  /* font-size: ${({ theme }) => theme.fontSize.base}; */
  font-size: 18px;
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  color: ${({ theme }) => theme.palette.text.primary};
  line-height: ${({ theme }) => theme.lineHeight.relaxed};
`

// End - CSS injected into Content

const OuterSection = styled.article`
  display: flex;
  justify-content: center;
  padding-left: ${({ theme }) => theme.spacing[4]};
  padding-right: ${({ theme }) => theme.spacing[4]};
  ${({ theme }) => theme.device.sm} {
    padding-left: ${({ theme }) => theme.spacing[4]};
    padding-right: ${({ theme }) => theme.spacing[4]};
  }
  ${({ theme }) => theme.device.md} {
    padding-left: ${({ theme }) => theme.spacing[4]};
    padding-right: ${({ theme }) => theme.spacing[4]};
  }
`

const InnerSection = styled.div`
  margin: 0 auto;
  max-width: 700px;
  min-width: 0;
  width: 100%;
  ${common}
`

const FeaturedImage = styled.img`
  width: 80vw;
`

const Header = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[3]};
  ${({ theme }) => theme.device.sm} {
    margin-bottom: ${({ theme }) => theme.spacing[8]};
  }
`

const TimeWrapperOuter = styled.div`
  display: block;
  text-align: center;
`

const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSize["3xl"]};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  color: ${({ theme }) => theme.palette.text.primary};
  line-height: ${({ theme }) => theme.lineHeight.normal};
  text-align: center;
  margin: ${({ theme }) => theme.spacing[3]} 0;

  & > a {
    color: inherit;
    text-decoration: none;
  }
  ${({ theme }) => theme.device.sm} {
    font-size: ${({ theme }) => theme.fontSize["4xl"]};
  }
  ${({ theme }) => theme.device.md} {
    font-size: ${({ theme }) => theme.fontSize["5xl"]};
  }
`

const Content = styled.section`
  ${common}
  h1,h2,h3,h4 {
    font-family: ${({ theme }) => theme.fontFamily.headings};
    font-size: ${({ theme }) => theme.fontSize.base};
    font-weight: ${({ theme }) => theme.fontWeight.normal};
    color: ${({ theme }) => theme.palette.text.primary};
    line-height: ${({ theme }) => theme.lineHeight.normal};
  }
  h1 {
    margin: ${({ theme }) => theme.spacing[3]} 0;
    font-size: ${({ theme }) => theme.fontSize["3xl"]};
    font-weight: ${({ theme }) => theme.fontWeight.normal};
    text-align: center;
  }
  h2 {
    margin: ${({ theme }) => theme.spacing[1]} 0;
    font-size: ${({ theme }) => theme.fontSize.xl};
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  }
  h3 {
    margin: ${({ theme }) => theme.spacing[1]} 0;
    font-size: ${({ theme }) => theme.fontSize.lg};
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  }
  h4 {
    margin: ${({ theme }) => theme.spacing[1]} 0;
    font-size: ${({ theme }) => theme.fontSize.lg};
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  }
  p,
  li {
    white-space: pre-wrap;
    margin-bottom: ${({ theme }) => theme.spacing[5]};
  }
  p {
    min-width: 100%;
  }
  small {
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
  img {
    width: 100%;
    height: auto;
    opacity: 0.9;
  }
  img + br + small {
    display: block;
    margin-top: -3em;
    margin-bottom: 1.5em;
    text-align: center;
  }
  a {
    color: ${({ theme }) => theme.palette.primary.dark};
    text-decoration: none;
  }
  & > blockquote {
    border-left-width: 3px;
    border-left-style: solid;
    border-left-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.text.secondary};
    line-height: ${({ theme }) => theme.lineHeight.normal};
  }
  ul,
  ol {
    margin: 0 0 1.5em;
    min-width: 100%;
    max-width: 100%;
    padding-left: 1.3em;
    padding-right: 1.5em;
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
  li {
    padding-left: 0.3em;
  }

  ${({ theme }) => theme.device.sm} {
    h1 {
      font-size: ${({ theme }) => theme.fontSize["4xl"]};
    }
  }
  ${({ theme }) => theme.device.md} {
    h1 {
      font-size: ${({ theme }) => theme.fontSize["5xl"]};
      margin-bottom: ${({ theme }) => theme.spacing[10]};
    }
  }
`

export default BlogPost
