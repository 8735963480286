import { CAREER_INTRODUCE_SUCCESS } from "./constants"

const initialState = {
  introduced: null,
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case CAREER_INTRODUCE_SUCCESS:
      return {
        ...state,
        introduced: {
          ...state.introduced,
          [payload.introduction.id]: payload.introduction.input.data,
        },
      }
    default:
      return state
  }
}
