import axios from "axios"

const SENDGRID_BASE_URL = "https://api.sendgrid.com/v3"
const SENDGRID_KEY =
  "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"

const BOOST_LIST_ID = "00a5ed58-47a1-414b-8bcb-7ce4a45e2748"

const config = {
  baseURL: SENDGRID_BASE_URL,
  headers: {
    "content-type": "application/json",
    authorization: `Bearer ${SENDGRID_KEY}`,
  },
}

class Sendgrid {
  constructor() {
    this.instance = axios.create(config)
  }

  doAddEmailToBoostList = email =>
    this.instance({
      method: "put",
      url: "marketing/contacts",
      data: JSON.stringify({
        list_ids: [BOOST_LIST_ID],
        contacts: [
          {
            email,
          },
        ],
      }),
    })
}

let sendgridInstance

const sendgrid = (function () {
  if (sendgridInstance) return sendgridInstance
  sendgridInstance = new Sendgrid()
  return sendgridInstance
})()

export default sendgrid
