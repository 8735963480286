import styled, { css } from "styled-components"
import { marginCSS } from "../CSS"

const widthCSS = css`
  width: 100%;
  & :nth-child(n + 2) {
    margin-top: 3rem;
  }
  ${({ theme }) => theme.breakpoint.up.md} {
    width: 43%;
    margin-left: 3.5%;
    margin-right: 3.5%;
    & :nth-child(-n + 2) {
      margin-top: 0px;
    }
    & :nth-child(n + 3) {
      margin-top: 7%;
    }
  }
  ${({ theme }) => theme.breakpoint.up.lg} {
    width: 25.33333%;
    margin-left: 4%;
    margin-right: 4%;
    & :nth-child(-n + 3) {
      margin-top: 0px;
    }
    & :nth-child(n + 4) {
      margin-top: 8%;
    }
  }
`

export default styled.div`
  ${widthCSS};
  ${marginCSS};
`
