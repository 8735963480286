import React from "react"

import Layout from "../../layouts/Primary"

function DataViz() {
  return (
    <Layout>
      <div>
        <div>DataViz</div>
      </div>
    </Layout>
  )
}

export default DataViz
