import styled from "styled-components"
import { marginCSS } from "../CSS"

export default styled.div`
  display: flex;
  flex-wrap: wrap;
  ${({ theme }) => theme.breakpoint.up.md} {
    margin-left: -3.5%;
    margin-right: -3.5%;
  }
  ${({ theme }) => theme.breakpoint.up.lg} {
    margin-left: -4%;
    margin-right: -4%;
  }
  ${marginCSS}
`
