import { createGlobalStyle } from "styled-components"
import reset from "styled-reset"

export const GlobalStyle = createGlobalStyle`
  ${reset}

  *, *:before, *:after {
    box-sizing: border-box;
  }

  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: ${({ theme }) => theme.fontFamily.default};
    font-size: ${({ theme }) => theme.fontSize.lg};
    color: ${({ theme }) => theme.palette.text.primary};
    vertical-align: baseline;
  }

  body {
    line-height: ${({ theme }) => theme.lineHeight.snug};
    letter-spacing: ${({ theme }) => theme.letterSpacing.normal};
    background-color: ${({ theme }) => theme.colors.white};
  }

  em {
    font-style: italic;
  }

  a {
    cursor: pointer;
  }

  strong {
    font-weight: ${({ theme }) => theme.fontWeight.bold}
  }

  blockquote {
    min-width: 100%;
    padding: 0 1.3em;
    border-left-width: 3px;
    border-left-style: solid;
    border-left-color: ${({ theme }) => theme.colors.gray[500]};
    font-size: 1.1em;
  }

  button:focus {
    outline: none;
  }

  
`
