import React from "react"
import styled, { css } from "styled-components"
import { navigate } from "gatsby"
import TextLink from "../TextLink"
import Button from "../Button"

import { HCN_WEB_APP } from "../../navigation/constants"

function Menu(props) {
  const { menu = [] } = props

  const handleNavigate = React.useCallback(item => {
    if (item.to || item.uri) {
      if (item.to) {
        navigate(item.to)
      }
      if (item.uri) {
        window.open(item.uri, "_blank")
      }
    }
  }, [])

  return (
    <Wrapper>
      {menu.map(item => {
        if (item.data) {
          return (
            <ul key={item.id}>
              <Title>{item.title}</Title>
              {item.data.map(l => {
                if (l.to) {
                  return (
                    <li key={l.id}>
                      <TextLink to={l.to}>{l.title}</TextLink>
                    </li>
                  )
                } else {
                  return null
                }
              })}
            </ul>
          )
        } else if (item.to) {
          return (
            <ul key={item.id}>
              <Title>{item.title}</Title>
              <li>
                <TextLink to={item.to}>{item.title}</TextLink>
              </li>
            </ul>
          )
        } else {
          return null
        }
      })}
      <Button
        title="Login"
        item={{
          title: "Login",
          id: "topnav-hcn-login-navigation",
          uri: HCN_WEB_APP,
        }}
        sx={css`
          font-weight: 500;
        `}
        variant="outlined"
        title="Login"
        onClick={handleNavigate}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  white-space: nowrap;
  width: 100%;
  margin-bottom: -${({ theme }) => theme.spacing[3]};
  & > ul {
    margin-right: ${({ theme }) => theme.spacing[0]};
    flex-basis: 100%;
    margin-bottom: ${({ theme }) => theme.spacing[3]};
  }
  & > ul > li {
    margin-top: ${({ theme }) => theme.spacing[3]};
    margin-bottom: ${({ theme }) => theme.spacing[3]};
  }
  ${({ theme }) => theme.breakpoint.up.sm} {
    & > ul {
      margin-right: ${({ theme }) => theme.spacing[8]};
      flex-basis: ${({ theme }) => `calc(50% - ${theme.spacing[8]})`};
    }
  }
  ${({ theme }) => theme.breakpoint.up.md} {
    & > ul {
      margin-right: ${({ theme }) => theme.spacing[6]};
      flex-basis: ${({ theme }) => `calc(33% - ${theme.spacing[6]})`};
    }
  }
  ${({ theme }) => theme.breakpoint.up.lg} {
    & > ul {
      margin-right: ${({ theme }) => theme.spacing[6]};
      flex-basis: ${({ theme }) => `calc(50% - ${theme.spacing[6]})`};
    }
  }
  ${({ theme }) => theme.breakpoint.up.xl} {
    & > ul {
      margin-right: ${({ theme }) => theme.spacing[8]};
      flex-basis: ${({ theme }) => `calc(33% - ${theme.spacing[8]})`};
    }
  }
`

const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSize["xl"]};
  font-family: ${({ theme }) => theme.fontFamily.headings};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`

export default Menu
