import React, {
  useEffect,
  useState,
  useRef,
  lazy,
  Suspense,
  useCallback,
} from "react"
import SpriteText from "three-spritetext"
import styled from "styled-components"

import Layout from "../../layouts"

import Button from "../../components/Button"
import IconButton from "../../components/IconButton"
import Spinner from "../../components/Spinner"

import PlayIcon from "../../components/Icons/Play"
import PauseIcon from "../../components/Icons/Pause"
import RefreshIcon from "../../components/Icons/Refesh"
import LockIcon from "../../components/Icons/Lock"
import UnlockIcon from "../../components/Icons/Unlock"

import useWindowSize from "../../hooks/useWindowSize"

import { DATA_PA } from "../../navigation/constants"

// Fix for client-side only lib - https://www.gatsbyjs.com/docs/using-client-side-only-packages/
const ForceGraph3D = lazy(() => import("react-force-graph-3d"))

const dataset = {
  "ip-all-with-domain": {
    title: "Abilities",
    import: import("/static/ip-all-with-domain.json"),
  },
  "ip-all-scale-item": {
    title: "Tasks",
    import: import("/static/ip-all-scale-item.json"),
  },
  "pa-test-set-scale-item": {
    title: "InfoSec PA tasks",
    import: import("/static/pa-test-set-scale-item.json"),
  },
  "pa-test-with-domain": {
    title: "InfoSec PA Quests",
    import: import("/static/pa-test-set-with-domain.json"),
  },
  "big5-scale-item": {
    title: "Big-5 tasks",
    import: import("/static/big5-scale-item.json"),
  },
  "big5-with-domain": {
    title: "Big-5",
    import: import("/static/big5-with-domain.json"),
  },
  "cybersecurity-nice": {
    title: "Cybersecurity",
    import: import("/static/cybersecurity-nice.json"),
  },
}

function PeopleAnalytics() {
  const isSSR = typeof window === "undefined"
  const { height, width } = useWindowSize()
  const [loading, setLoading] = useState(true)
  const [pause, setPause] = useState(false)
  const [data, setData] = useState()
  const [active, setActive] = useState()
  const [locked, setLocked] = useState(false)
  const fgRef = useRef(null)

  const handleEngineState = useCallback(() => {
    if (pause) {
      fgRef.current.resumeAnimation()
      setPause(false)
    } else {
      fgRef.current.pauseAnimation()
      setPause(true)
    }
  }, [pause])

  const handleDataSet = useCallback(
    async filename => {
      let datasetName = Object.keys(dataset)[0]
      if (
        filename &&
        Object.keys(dataset).filter(d => d === filename).length > 0
      ) {
        datasetName = filename
      }
      const newData = await dataset[datasetName].import
      setActive(datasetName)
      setData(newData)
      if (pause) {
        handleEngineState()
      }
    },
    [pause, handleEngineState]
  )

  useEffect(() => {
    handleDataSet()
  }, [handleDataSet])

  useEffect(() => {
    if (fgRef.current) {
      fgRef.current.d3Force("charge").strength(-120)
    }
  }, [fgRef.current])

  useEffect(() => {
    if (data) {
      setLoading(false)
    }
  }, [data])

  const handleRefresh = useCallback(() => {
    fgRef.current.d3ReheatSimulation()
    fgRef.current.refresh()
  }, [])

  const handleLock = useCallback(() => {
    setLocked(!locked)
  }, [locked])

  const Loading = () => (
    <Layout
      fullscreen
      title="Knowledge Graph Insight"
      url={DATA_PA}
      description="Explore data from the Human Cloud Knowledge Graph"
      canonicalURL={DATA_PA}
      image="https://123abc.com/assets/kg.png"
    >
      <CanvasWrapper>
        <Spinner mt={4} center />
      </CanvasWrapper>
    </Layout>
  )

  if (loading) {
    return <Loading />
  }

  const SCALE_COLOR = "#f7a82a"
  const DOMAIN_COLOR = "#f23ce9"
  const FACET_COLOR = "#1bdb18"
  const KEY_PLUS_COLOR = "#30c403"
  const KEY_MINUS_COLOR = "#f44b29"
  const SCALE_SIZE = 6
  const DOMAIN_SIZE = 8
  const FACET_SIZE = 6
  const ITEM_SIZE = 4

  return (
    <Layout
      fullscreen
      title="Knowledge Graph Insight"
      url={DATA_PA}
      description="Explore data from the Human Cloud Knowledge Graph"
      canonicalURL={DATA_PA}
      image="https://123abc.com/assets/kg.png"
    >
      {!isSSR && (
        <CanvasWrapper height={height} width={width}>
          <Suspense fallback={<Loading />}>
            <ForceGraph3D
              ref={fgRef}
              height={height - 64}
              width={width}
              backgroundColor="#000012"
              graphData={data}
              nodeId="id"
              nodeColor={node => {
                if (node.label === "Scale") return SCALE_COLOR
                if (node.label === "Domain") return DOMAIN_COLOR
                if (node.label === "Facet") return FACET_COLOR
                if (node.type === "category") return FACET_COLOR
                if (node.type === "area") return DOMAIN_COLOR
                if (node.type === "ksat__knowledge") return "#add7f6"
                if (node.type === "ksat__skill") return "#79c99e"
                if (node.type === "ksat__ability") return "#d90368"
                if (node.type === "ksat__task") return "#2667ff"
                return "#1d5ae0"
              }}
              nodeVal={node => {
                if (node.label === "Scale") return SCALE_SIZE
                if (node.label === "Domain") return DOMAIN_SIZE
                if (node.label === "Facet") return FACET_SIZE
                if (node.type === "ksat__ability") return 12

                return ITEM_SIZE
              }}
              nodeLabel={node => {
                if (node.label === "Item") return node.caption
                if (node.type && node.type.split("__")[0] === "ksat")
                  return node.text
                if (node.type === "category") return node.text
                if (node.type === "area") return node.text
                if (node.type === "role") return node.text
                return node.id
              }}
              nodeThreeObject={node => {
                if (node.label === "Facet") {
                  const sprite = new SpriteText(node.caption)
                  sprite.material.depthWrite = false // make sprite background transparent
                  sprite.color = FACET_COLOR
                  sprite.textHeight = 11
                  return sprite
                }
                if (node.label === "Domain") {
                  const sprite = new SpriteText(node.caption)
                  sprite.material.depthWrite = false // make sprite background transparent
                  sprite.color = DOMAIN_COLOR
                  sprite.textHeight = 9
                  return sprite
                }
                if (node.type === "category") {
                  const sprite = new SpriteText(node.name)
                  sprite.material.depthWrite = false // make sprite background transparent
                  sprite.color = FACET_COLOR
                  sprite.textHeight = 11
                  return sprite
                }
                if (node.type === "area") {
                  const sprite = new SpriteText(node.name)
                  sprite.material.depthWrite = false // make sprite background transparent
                  sprite.color = SCALE_COLOR
                  sprite.textHeight = 11
                  return sprite
                }
                if (node.type === "role") {
                  const sprite = new SpriteText(node.name)
                  sprite.material.depthWrite = false // make sprite background transparent
                  sprite.color = FACET_COLOR
                  sprite.textHeight = 9
                  return sprite
                }
              }}
              linkColor={link => {
                if (link.key) {
                  if (link.key > 0) {
                    return KEY_PLUS_COLOR
                  } else {
                    return KEY_MINUS_COLOR
                  }
                }
              }}
              onNodeDragEnd={
                locked
                  ? node => {
                      node.fx = node.x
                      node.fy = node.y
                      node.fz = node.z
                    }
                  : () => {}
              }
              enableNavigationControls={!pause}
              // cooldownTicks={200}
              onRenderFramePost={() => console.log("LOADED")}
            />
            <Controls>
              <div>
                {Object.keys(dataset).map(k => (
                  <Button
                    size="small"
                    color={k === active ? "pink.dark" : "pink.light"}
                    ml={1}
                    mb={1}
                    key={k}
                    title={dataset[k].title}
                    item={k}
                    onClick={handleDataSet}
                  />
                ))}
              </div>
              <ControlsInner>
                <IconButton
                  mt={4}
                  mb={2}
                  color="#fff"
                  onClick={handleEngineState}
                  bgColor="#f3f3f3"
                >
                  {pause ? <PlayIcon /> : <PauseIcon />}
                </IconButton>
                <IconButton
                  mb={2}
                  color="#fff"
                  onClick={handleRefresh}
                  bgColor="#f3f3f3"
                >
                  <RefreshIcon />
                </IconButton>
                <IconButton
                  mb={2}
                  color="#fff"
                  onClick={handleLock}
                  bgColor="#f3f3f3"
                >
                  {locked ? <UnlockIcon /> : <LockIcon />}
                </IconButton>
              </ControlsInner>
            </Controls>
          </Suspense>
        </CanvasWrapper>
      )}
    </Layout>
  )
}

const CanvasWrapper = styled.div`
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
`

const Controls = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 16px;
`

const ControlsInner = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection = "column" }) => flexDirection};
`

export default PeopleAnalytics
