import React from "react"
// import { StaticQuery, graphql } from "gatsby"
import Seo from "../Seo"

class ErrorBoundary extends React.Component {
  state = {
    eventId: undefined,
    hasError: undefined,
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    if (typeof window !== "undefined" && window.Sentry) {
      window.Sentry.withScope(scope => {
        scope.setExtras(errorInfo)
        const eventId = window.Sentry.captureException(error)
        this.setState({ eventId })
      })
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <Seo keywords={["123abc"]} title="Error Occured" url="" />
          <main className="flex flex-col justify-center bg-brand items-center h-screen w-screen text-white font-thin">
            <p className="mb-8">An unexpected error occurred.</p>
          </main>
        </>
      )
    }
    // when there's not an error, render children untouched
    return this.props.children
  }
}

export default ErrorBoundary
