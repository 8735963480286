import axios from "axios"

const FIREBASE_BASE_URL = "https://abc-web-45f9b.firebaseio.com"

const config = {
  baseURL: FIREBASE_BASE_URL,
  headers: {
    "content-type": "application/json",
  },
}

class Firebase {
  constructor() {
    this.instance = axios.create(config)
  }

  doPostJobApplication = data =>
    this.instance({
      method: "post",
      url: "applications.json",
      data: data,
    })
}

let firebaseInstance

const firebase = (function () {
  if (firebaseInstance) return firebaseInstance
  firebaseInstance = new Firebase()
  return firebaseInstance
})()

export default firebase
