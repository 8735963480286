import React from "react"
import { css } from "styled-components"

import Box from "../Box"

function ErrorForm(props) {
  const { error } = props
  return (
    <Box
      mt={1}
      sx={css`
        color: ${({ theme }) => theme.palette.error.main};
        padding-left: ${({ theme }) => theme.spacing[4]};
        font-family: ${({ theme }) => theme.fontFamily.default};
        font-weight: ${({ theme }) => theme.fontWeight.medium};
        letter-spacing: ${({ theme }) => theme.letterSpacing.normal};
        font-size: 12px;
      `}
      as="span"
    >
      {error}
    </Box>
  )
}

export default ErrorForm
