import * as Sentry from "@sentry/browser"

import { wrapRootElement as wrap } from "./root-wrapper"

export const onClientEntry = () => {
  Sentry.init({
    dsn:
      "https://5dca9b0f35174f40882af7dcaa6f9c4a@o522212.ingest.sentry.io/5633347",
    environment: "dev",
    attachStacktrace: true,
  })

  window.Sentry = Sentry
}

export const wrapRootElement = wrap
