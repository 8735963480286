import { PRIVACY, TERMS } from "./constants"

export const legal = [
  {
    id: "legal-privacy-link",
    url: PRIVACY,
    title: "Privacy",
  },
  {
    id: "legal-terms-link",
    url: TERMS,
    title: "Terms",
  },
]
