export const social = [
  {
    id: "linkedin-official",
    url: "#",
    handle: "#",
    title: "LinkedIn",
    show: false,
  },
  {
    id: "twitter-official",
    url: "#",
    handle: "#",
    title: "Twitter",
    show: false,
  },
  {
    id: "github-official",
    url: "#",
    handle: "#",
    title: "GitHub",
    show: false,
  },
]
