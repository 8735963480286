import React from "react"
import styled from "styled-components"

import { H1, P, SubTitle } from "../Typography"

function HeroSection(props) {
  const { title, subTitle, paragraph, backgroundImage } = props
  return (
    <SectionWrapper backgroundImage={backgroundImage}>
      <HeroInnerWrapper>
        <HeroContentWrapper>
          <HeroContent>
            <HeroTitle>{title}</HeroTitle>
            <HeroSubtitle mt={4}>{subTitle}</HeroSubtitle>
            <P mt={4} mb={8}>
              {paragraph}
            </P>
          </HeroContent>
        </HeroContentWrapper>
        <HeroImageWrapper>
          <div
            style={{
              width: 480,
              height: 600,
              backgroundColor: "rgba(255, 0, 0, 0.3)",
            }}
          />
        </HeroImageWrapper>
      </HeroInnerWrapper>
    </SectionWrapper>
  )
}

const SectionWrapper = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 0;
  width: 100%;
  color: #fff;
  overflow: hidden;
  background: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  background-color: rgb(29, 29, 29);
  overflow: hidden;
`

const HeroInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0px auto;
  padding: 24px 24px;
  max-width: 1400px;
  height: 100%;
  ${({ theme }) => theme.breakpoint.up.md} {
    padding: 24px 32px;
  }
  ${({ theme }) => theme.breakpoint.up.lg} {
    padding: 24px 36px;
  }
  ${({ theme }) => theme.breakpoint.up.xl} {
    padding: 24px 56px;
  }
`

const HeroContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 100px;
  flex: 1 1 0%;
`

const HeroContent = styled.div`
  min-width: unset;
  ${({ theme }) => theme.breakpoint.up.md} {
    min-width: 500px;
  }
  ${({ theme }) => theme.breakpoint.up.xl} {
    min-width: 580px;
  }
`

const HeroImageWrapper = styled.div`
  display: flex;
  padding-top: 36px;
  padding-left: 25px;
  justify-content: flex-end;
  flex: 2 1 0%;
  display: none;
  // TODO: remove when image is added
  background-color: rgba(56, 56, 56, 0.3);
  ${({ theme }) => theme.breakpoint.up.md} {
    display: flex;
  }
`

const HeroTitle = styled(H1)`
  font-weight: 700;
`

const HeroSubtitle = styled(SubTitle)`
  color: ${({ theme }) => theme.palette.primary.main};
`

export default HeroSection
