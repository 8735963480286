import React, { useMemo } from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"

import Box from "../Box"

const JobPost = ({ post, toTitlePath, maxWidth }) => {
  const location = useMemo(() => {
    let { name } = post.tags.find(d => d.name.startsWith("#loc_"))
    name = name.split("_")[1]
    return name
  }, [post.tags])

  const employmentType = useMemo(() => {
    let tag = post.tags.find(d => d.name.startsWith("#emp_"))
    if (tag) {
      tag = tag.name.split("_")[1]
      return tag
    }
  }, [post.tags])

  // const createMarkup = useMemo(() => {
  //   return {
  //     __html: DOMPurify.sanitize(post.html),
  //   }
  // }, [post.html])

  return (
    <OuterSection>
      <InnerSection maxWidth={maxWidth}>
        {post.feature_image ? (
          <FeaturedImage src={post.feature_image} alt={post.title} />
        ) : null}
        <Header>
          <Title>
            {toTitlePath ? (
              <Link to={toTitlePath}>{post.title}</Link>
            ) : (
              post.title
            )}
          </Title>
          <Box
            mb={2}
            sx={css`
              font-size: ${({ theme }) => theme.fontSize.sm};
              color: ${({ theme }) => theme.palette.text.secondary};
              ${({ theme }) => theme.device.sm} {
                font-size: ${({ theme }) => theme.fontSize.base};
              }
            `}
          >
            At 123abc.com
          </Box>
          {location && (
            <Box
              mt={2}
              mb={2}
              mr={2}
              sx={css`
                font-size: ${({ theme }) => theme.fontSize.sm};
                color: #fff;
                background-color: ${({ theme }) => theme.palette.safety.main};
                display: inline-block;
                padding: 4px 8px;
                border-radius: 4px;
                border-color: ${({ theme }) => theme.palette.safety.main};
                border-width: 1px;
                border-style: solid;
                text-transform: uppercase;
                letter-spacing: 1px;
                ${({ theme }) => theme.device.sm} {
                  font-size: ${({ theme }) => theme.fontSize.base};
                }
              `}
            >
              <Box>{location}</Box>
            </Box>
          )}
          {employmentType && (
            <Box
              mt={2}
              mb={2}
              mr={2}
              sx={css`
                font-size: ${({ theme }) => theme.fontSize.sm};
                color: #000;
                /* background-color: ${({ theme }) =>
                  theme.palette.safety.main}; */
                display: inline-block;
                padding: 4px 8px;
                border-radius: 4px;
                border-color: #000;
                border-width: 1px;
                border-style: solid;
                text-transform: uppercase;
                letter-spacing: 1px;
                ${({ theme }) => theme.device.sm} {
                  font-size: ${({ theme }) => theme.fontSize.base};
                }
              `}
            >
              <Box>{employmentType}</Box>
            </Box>
          )}
        </Header>
        <Content dangerouslySetInnerHTML={{ __html: post.html }} />
      </InnerSection>
    </OuterSection>
  )
}

// CSS injected into Content
const common = css`
  font-family: ${({ theme }) => theme.fontFamily.default};
  /* font-size: ${({ theme }) => theme.fontSize.base}; */
  font-size: 18px;
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  color: ${({ theme }) => theme.palette.text.primary};
  line-height: ${({ theme }) => theme.lineHeight.relaxed};
`

// End - CSS injected into Content

const OuterSection = styled.article`
  display: flex;
  justify-content: center;
`

const InnerSection = styled.div`
  margin: 0 auto;
  max-width: ${({ maxWidth, theme }) =>
    maxWidth ? theme.screens[maxWidth] : theme.screens.md};
  min-width: 0;
  width: 100%;
  ${common}
`

const FeaturedImage = styled.img`
  width: 80vw;
`

const Header = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[3]};
  ${({ theme }) => theme.device.sm} {
    margin-top: ${({ theme }) => theme.spacing[5]};
    margin-bottom: ${({ theme }) => theme.spacing[5]};
  }
`

const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSize["2xl"]};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.palette.text.primary};
  line-height: ${({ theme }) => theme.lineHeight.normal};
  text-align: left;
  margin-top: ${({ theme }) => theme.spacing[3]};

  & > a {
    color: inherit;
    text-decoration: none;
  }
  ${({ theme }) => theme.device.sm} {
    font-size: ${({ theme }) => theme.fontSize["2xl"]};
  }
  ${({ theme }) => theme.device.md} {
    font-size: ${({ theme }) => theme.fontSize["3xl"]};
  }
`

const Content = styled.section`
  ${common}
  h1,h2,h3,h4 {
    font-family: ${({ theme }) => theme.fontFamily.headings};
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-weight: ${({ theme }) => theme.fontWeight.normal};
    color: ${({ theme }) => theme.palette.text.primary};
    line-height: ${({ theme }) => theme.lineHeight.normal};
    ${({ theme }) => theme.device.sm} {
      font-size: ${({ theme }) => theme.fontSize.base};
    }
  }
  h1 {
    margin: ${({ theme }) => theme.spacing[3]} 0;
    font-size: ${({ theme }) => theme.fontSize["2xl"]};
    font-weight: ${({ theme }) => theme.fontWeight.normal};
    text-align: left;
  }
  h2 {
    margin: ${({ theme }) => theme.spacing[1]} 0;
    font-size: ${({ theme }) => theme.fontSize.xl};
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  }
  h3 {
    margin: ${({ theme }) => theme.spacing[1]} 0;
    font-size: ${({ theme }) => theme.fontSize.lg};
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  }
  h4 {
    margin: ${({ theme }) => theme.spacing[2]} 0;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: ${({ theme }) => theme.fontSize.base};
    ${({ theme }) => theme.device.sm} {
      font-size: ${({ theme }) => theme.fontSize.lg};
    }
  }
  li {
    white-space: pre-wrap;
    font-size: ${({ theme }) => theme.fontSize.sm};
    margin-bottom: ${({ theme }) => theme.spacing[2]};
    ${({ theme }) => theme.device.sm} {
      font-size: ${({ theme }) => theme.fontSize.base};
    }
  }
  p {
    min-width: 100%;
    white-space: pre-wrap;
    font-size: ${({ theme }) => theme.fontSize.sm};
    margin-bottom: ${({ theme }) => theme.spacing[6]};
    ${({ theme }) => theme.device.sm} {
      font-size: ${({ theme }) => theme.fontSize.base};
    }
  }
  small {
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
  img {
    width: 100%;
    height: auto;
    opacity: 0.9;
  }
  img + br + small {
    display: block;
    margin-top: -3em;
    margin-bottom: 1.5em;
    text-align: center;
  }
  a {
    color: ${({ theme }) => theme.palette.primary.dark};
    text-decoration: none;
  }
  & > blockquote {
    border-left-width: 3px;
    border-left-style: solid;
    border-left-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.text.secondary};
    line-height: ${({ theme }) => theme.lineHeight.normal};
  }
  ul,
  ol {
    margin: 0 0 1.5em;
    min-width: 100%;
    max-width: 100%;
    padding-left: 1.3em;
    padding-right: 1.5em;
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
  li {
    padding-left: 0.3em;
  }

  ${({ theme }) => theme.device.sm} {
    h1 {
      font-size: ${({ theme }) => theme.fontSize["4xl"]};
    }
  }
  ${({ theme }) => theme.device.md} {
    h1 {
      font-size: ${({ theme }) => theme.fontSize["5xl"]};
      margin-bottom: ${({ theme }) => theme.spacing[10]};
    }
  }
`

export default JobPost
