import * as c from "./constants"

export const menu = [
  // {
  //   title: "Learn",
  //   id: "topnav-learn-topic",
  //   data: [
  //     {
  //       title: "DeTa Basics",
  //       id: "topnav-hc-basics-navigation",
  //       to: c.HUMAN_CAPITAL_BASICS,
  //     },
  //     {
  //       itemSeperator: true,
  //       id: "sep1",
  //     },
  //     {
  //       title: "Boost",
  //       id: "topnav-weekly-catchup-navigation",
  //       to: c.BLOG,
  //       highlight: true,
  //     },
  //   ],
  // },
  // {
  //   title: "Talent",
  //   id: "topnav-talent-home",
  //   to: c.ABOUT_US,
  // },
  // {
  //   title: "Employers",
  //   id: "topnav-businesses-home",
  //   to: c.ABOUT_US,
  // },
  // {
  //   title: "Providers",
  //   id: "topnav-businesses-home",
  //   to: c.ABOUT_US,
  // },
  // {
  //   title: "Developers",
  //   id: "topnav-developers-home",
  //   to: c.ABOUT_US,
  // },
  {
    title: "Human Cloud",
    id: "topnav-hcn-home",
    data: [
      {
        title: "The Human Cloud Manifesto",
        id: "topnav-hcn-talent-navigation",
        uri: "https://humancloudmanifesto.org/",
        // to: c.ABOUT_US,
      },
      // {
      //   title: "Employer",
      //   id: "topnav-hcn-employer-navigation",
      //   to: c.OUR_COMMITMENTS,
      // },
      // { title: "Provider", id: "topnav-hcn-provider-navigation", to: c.BLOG },
      {
        itemSeperator: true,
        id: "sep2",
      },
      {
        title: "Api Data Insight",
        id: "topnav-hcn-developer-navigation",
        to: c.DATA_PA,
        highlight: true,
      },
    ],
  },
  {
    title: "Boost",
    id: "topnav-weekly-catchup-navigation",
    to: c.BLOG,
    highlight: true,
  },

  {
    title: "Who we are",
    id: "topnav-about-topic33fff",
    data: [
      // {
      //   title: "About us",
      //   id: "topnav-about-us-navigation33ffff",
      //   to: c.ABOUT_US,
      // },
      // {
      //   title: "Our commitments",
      //   id: "topnav-our-commitments-navigation33fff",
      //   to: c.OUR_COMMITMENTS,
      // },
      {
        title: "Career",
        id: "topnav-career-navigation",
        to: c.CAREER,
      },
    ],
  },
]
