import React from "react"
import styled from "styled-components"

import Layout from "../layouts/Primary"

function Learn() {
  return (
    <Layout>
      <Wrapper>
        <Title>Learn</Title>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div``

const Title = styled.h1``

export default Learn
