import React from "react"
import { Provider } from "react-redux"
import { ThemeProvider } from "styled-components"
import theme from "./src/theme/baseTheme"
import { PersistGate } from "redux-persist/integration/react"

import { store, persistor } from "./src/store"

export const wrapRootElement = ({ element }) => {
  const isBrowser = typeof window !== "undefined"
  if (isBrowser) {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>{element}</ThemeProvider>
        </PersistGate>
      </Provider>
    )
  } else {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>{element}</ThemeProvider>
      </Provider>
    )
  }
}
