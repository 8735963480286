import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Helmet from "react-helmet"
import Container from "../components/Container"
import Section from "../components/Section"
import { GridContainer, GridItem, GridIconItem } from "../components/Grid"
import { H2, P, SubTitle } from "../components/Typography"

import { HeroSection2 } from "../components/HeroSection"

import Layout from "../layouts/Primary"

import site from "../data/siteMetadata"

const IndexPage = () => {
  return (
    <Layout
      title="123abc | Human Cloud Network"
      url={site.siteUrl}
      canonicalURL={site.siteUrl}
    >
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            parentOrganization: {
              "@type": "Organization",
              name: "Xippl Inc.",
            },
            name: "123abc.com",
            foundingDate: "2017",
            logo: "https://123abc.com/assets/icon.png",
            url: "https://123abc.com",
            contactPoint: {
              "@type": "ContactPoint",
              email: "hello@123abc.com",
            },
            founders: [
              {
                "@type": "Person",
                name: "Rene McCaine",
              },
            ],
          })}
        </script>
      </Helmet>
      <HeroSection2
        badgeText="GLOBAL GOAL 2030"
        title={["10x", "Human Capital"]}
        subTitle="Meet. Learn. Work."
        paragraph="123abc is building the Decentralized Talent Economy, a more efficient way to grow and connect people, businesses and talent developers."
        backgroundImage="https://firebasestorage.googleapis.com/v0/b/abc-web-45f9b.appspot.com/o/assets%2Fheroindexbg.svg?alt=media&token=4cbfee95-1041-4e1c-ab37-c6ce16cde5c2"
      />
      <Section id="mission" bgColor="#fff">
        <MissionContent />
      </Section>
      <Section id="human-cloud-network" bgColor="#f2f7ef">
        <HumanCloudNetworkContent />
      </Section>
    </Layout>
  )
}

export default IndexPage

function MissionContent() {
  return (
    <Container>
      <P>Our mission is:</P>
      <H2 mt={6} mr={0} mt_md={8}>
        To build a trusted and innovative talent network that powers people and
        businesses around the world.
      </H2>
      <SubTitle mt={6} mr={8} mt_md={8} mr_md={16}>
        We call it the Human Cloud Network, it's fully open, it's for everyone,
        it's a talent network designed for the digital age.
      </SubTitle>
    </Container>
  )
}

function HumanCloudNetworkContent() {
  return (
    <Container>
      <H2>The Human Cloud Network is for everyone</H2>
      <SubTitle mt={6} mr={8} mt_md={8} mr_md={16} maxWidth={480}>
        Make it easy for people to find the right skills to develop, for
        businesses to indicate what skills they need, invest in people
        development, and invite people into their teams.
      </SubTitle>
      <GridContainer mt={12}>
        <GridItem>
          <GridIconItem
            title="Mobile"
            description="The Human Cloud Network will be accessible to anyone with an entry-level smartphone and data connectivity."
            imgSize={150}
          >
            <StaticImage
              src="../images/mobile.png"
              alt="description"
              placeholder="blurred"
              layout="constrained"
              height={150}
            />
          </GridIconItem>
        </GridItem>
        <GridItem>
          <GridIconItem
            title="Insight"
            description="The Human Cloud Network gives full access to People Analytics for individuals and leaders without compromising privacy."
            imgSize={150}
          >
            <StaticImage
              src="../images/insight.png"
              alt="description"
              placeholder="blurred"
              layout="constrained"
              height={150}
            />
          </GridIconItem>
        </GridItem>
        <GridItem>
          <GridIconItem
            title="Augmented"
            description="Built with Augmented Intelligence supporting human-centered transparent data for responsible and ethical decisions."
            imgSize={150}
          >
            <StaticImage
              src="../images/network.png"
              alt="description"
              placeholder="blurred"
              layout="constrained"
              height={150}
            />
          </GridIconItem>
        </GridItem>
        <GridItem>
          <GridIconItem
            title="For the World"
            description="The Human Cloud Network will facilitate a more accessible, more connected global talent ecosystem."
            imgSize={150}
          >
            <StaticImage
              src="../images/earth-globe.png"
              alt="description"
              placeholder="blurred"
              layout="constrained"
              height={150}
            />
          </GridIconItem>
        </GridItem>
        <GridItem>
          <GridIconItem
            title="Scalable"
            description="The open-source Human Cloud protocol will foster a vibrant community of developers building products and services to help people access and use the Human Cloud Network."
          >
            <StaticImage
              src="../images/scale.png"
              alt="description"
              placeholder="blurred"
              layout="constrained"
              height={150}
            />
          </GridIconItem>
        </GridItem>
        <GridItem>
          <GridIconItem
            title="Privacy"
            description="The Human Cloud network is built on blockchain technology and designed with security & privacy in mind."
          >
            <StaticImage
              src="../images/profile.png"
              alt="description"
              placeholder="blurred"
              layout="constrained"
              height={150}
            />
          </GridIconItem>
        </GridItem>
      </GridContainer>
    </Container>
  )
}
