import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
// import { useStaticQuery, graphql } from "gatsby"

import ErrorBoundary from "../components/ErrorBoundary"
// import Root, { Main } from "../components/Root"
import NavigationBar from "../components/NavigationBar"
import Footer from "../components/Footer"
import Seo from "../components/Seo"
import { GlobalStyle } from "../theme/globalStyle"

import { menu } from "../navigation/menu"

const Layout = props => {
  const {
    children,
    fullscreen,
    title,
    url,
    description,
    image,
    canonicalURL,
  } = props
  return (
    <ErrorBoundary>
      <Root fullscreen={fullscreen}>
        <Seo
          title={title}
          url={url}
          description={description}
          image={image}
          canonicalURL={canonicalURL}
        />
        <GlobalStyle />
        <Container fullscreen={fullscreen}>
          <NavigationBar menu={menu} fixed={false} />
          {children}
        </Container>
        {!fullscreen && <Footer menu={menu} />}
      </Root>
    </ErrorBoundary>
  )
}

const fullscreenCss = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const relativeCss = css`
  width: 100% !important;
  position: relative !important;
`

const Root = styled.div`
  ${({ fullscreen }) => (fullscreen ? fullscreenCss : relativeCss)};
`

const Container = styled.div`
  ${({ fullscreen }) =>
    fullscreen ? "height: 100%;" : "min-height: 100vh !important;"};
  overflow-x: hidden !important;
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  fullscreen: false,
}

export default Layout
