import styled from "styled-components"
import { marginCSS, paddingCSS } from "../CSS"

const Box = styled.div`
  ${({ maxWidth, theme }) =>
    maxWidth && `max-width: ${theme.screens[maxWidth]}`};
  ${marginCSS};
  ${paddingCSS};
  ${({ sx }) => sx && sx};
`

export default Box
