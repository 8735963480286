import React from "react"
import styled from "styled-components"

import { H1, P, SubTitle } from "../Typography"
import Container from "../Container"
import Badge from "../Badge"

function HeroSection2(props) {
  const {
    title,
    subTitle,
    paragraph,
    backgroundImage,
    color,
    bgColor,
    badgeText,
    bgX,
    bgY,
  } = props

  return (
    <SectionWrapper
      backgroundImage={backgroundImage}
      color={color}
      bgColor={bgColor}
      bgX={bgX}
      bgY={bgY}
    >
      <Container>
        <HeroContentWrapper>
          <HeroContent>
            {badgeText && <Badge mb={0.5} text={badgeText} />}
            <HeroTitle>
              {title.map((t, i) => {
                if (title.length - i === 1) {
                  return <span key={i + "hero"}>{t}</span>
                }
                return (
                  <span key={i + "hero"}>
                    <span>{t}</span>
                    <br />
                  </span>
                )
              })}
            </HeroTitle>
            {subTitle && <HeroSubtitle mt={4}>{subTitle}</HeroSubtitle>}
            {paragraph && (
              <HeroParagraph mt={4} mb={8}>
                {paragraph}
              </HeroParagraph>
            )}
          </HeroContent>
        </HeroContentWrapper>
      </Container>
    </SectionWrapper>
  )
}

const SectionWrapper = styled.div`
  position: relative;
  display: flex;
  min-height: 60vh;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  color: ${({ color }) => color};
  overflow: hidden;
  background: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: ${({ bgX }) => bgX};
  background-position-y: ${({ bgY }) => bgY};
  background-color: ${({ bgColor }) => bgColor};
  overflow: hidden;
  ${({ theme }) => theme.breakpoint.up.md} {
    min-height: 80vh;
    max-height: 800px;
  }
`

const HeroContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 100px;
  height: 100%;
  flex: 1 1 0%;
`

const HeroContent = styled.div`
  min-width: unset;
  ${({ theme }) => theme.breakpoint.up.md} {
    min-width: 500px;
  }
  ${({ theme }) => theme.breakpoint.up.xl} {
    min-width: 580px;
  }
`

const HeroTitle = styled(H1)`
  font-weight: 700;
  font-size: 52px;
  line-height: 56px;
  ${({ theme }) => theme.breakpoint.up.sm} {
    font-size: 68px;
    line-height: 72px;
  }
  ${({ theme }) => theme.breakpoint.up.md} {
    font-size: 82px;
    line-height: 86px;
  }
  ${({ theme }) => theme.breakpoint.up.lg} {
    font-size: 96px;
    line-height: 104px;
  }
  ${({ theme }) => theme.breakpoint.up.xl} {
    font-size: 100px;
  }
`

const HeroSubtitle = styled(SubTitle)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 20px;
  ${({ theme }) => theme.breakpoint.up.sm} {
    font-size: 22px;
    line-height: 24px;
  }
  ${({ theme }) => theme.breakpoint.up.lg} {
    font-size: 24px;
    line-height: 26px;
  }
`

const HeroParagraph = styled(P)`
  /* width: 80vw; */
  max-width: 700px;
`

HeroSection2.defaultProps = {
  color: "#fff",
  bgColor: "rgb(29, 29, 29)",
  bgX: "center",
  bgY: "center",
}

export default HeroSection2
