/* eslint-disable curly */
/**
 * Promise based delay
 * @param {Number} milliseconds
 * @returns {Promise<void>} a new Promise that resolves with undefined after the set milliseconds.
 */
export const delay = milliseconds => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export const validatePhoneNumber = mobileNumber => {
  const regexp = /^\+[0-9]?()[0-9](\s|\S)(\d[0-9]{8,16})$/
  return regexp.test(mobileNumber)
}

export const timestamp = () => new Date().getTime()

export const objectToArray = object => {
  if (!object) return null
  return Object.entries(object).map(e => ({ ...e[1], id: e[0] }))
}

export const arrayToObjectMap = array => {
  if (!Array.isArray(array)) return undefined
  const obj = {}
  array.forEach(item => {
    const { id, ...rest } = item
    Object.assign(obj, { [id]: rest })
  })
  return obj
}

export const capitalize = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match =>
    match.toUpperCase()
  )

export const formatNumberToThousand = (
  number,
  decThousand = 1,
  decMillion = 1
) => {
  const n = Number.parseInt(number, 10)
  if (n > 999) {
    const calcDecimal = d => 10 ** d
    if (n >= 1000000) {
      return `${(
        Math.trunc((n / 1000000) * calcDecimal(decMillion)) /
        calcDecimal(decMillion)
      ).toString()}M`
    }
    if (n >= 1000) {
      return `${(
        Math.trunc((n / 1000) * calcDecimal(decThousand)) /
        calcDecimal(decThousand)
      ).toString()}K`
    }
  }
  return n.toString()
}

export const formatNumberToObject = (num, numberFormat = {}, settings = {}) => {
  const { groupingSeparator = ",", decimalSeparator = "." } = numberFormat
  const { decimals = 2 } = settings
  const [int = "0", dec] = num.toFixed(decimals).split(".")
  const intGrouped = int.replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    `$1${groupingSeparator}`
  )

  return { int: intGrouped, dec, decSep: decimalSeparator }
}
