import React from "react"
import styled from "styled-components"

import { H3, P } from "../Typography"

function GridIconItem(props) {
  const { children, title, description, imgSize = 150 } = props
  return (
    <div>
      <IconWrapper imgSize={imgSize}>{children}</IconWrapper>
      <H3 mt={3} mb={1}>
        {title}
      </H3>
      <P>{description}</P>
    </div>
  )
}

const IconWrapper = styled.div`
  width: ${({ imgSize }) => imgSize}px;
  height: ${({ imgSize }) => imgSize}px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
`

export default GridIconItem
