const theme = {
  palette: {
    type: "light",
    background: {
      primary: "#fff",
      secondary: "#f5f5f5",
      tertiary: "#fcfcfc",
      quaternary: "#fafafa",
      dp: {
        0: "#fff",
        1: "#f2f2f2",
        2: "#ededed",
        3: "#ebebeb",
        4: "#e8e8e8",
        6: "#e3e3e3",
        8: "#e0e0e0",
        12: "#dbdbdb",
        16: "#d9d9d9",
        24: "#d6d6d6",
      },
    },
    paper: {
      light: "#3afc3d",
      main: "#fff",
      dark: "#0357ba",
      opacity: "rgba(0,0,0,0.80)",
      contrastText: "rgba(0, 0, 0, 0.87)",
      contrastSubstitudeAndroid: "rgba(0,0,0,0.74)",
    },
    primary: {
      light: "#00F504",
      main: "#00E004",
      dark: "#00B803",
      opacity: "rgba(0,255,5,0.72)",
      contrastText: "#000000",
    },
    secondary: {
      light: "#5aa75e",
      main: "#319236",
      dark: "#2f8433",
      opacity: "rgba(49,146,54,0.72)",
      contrastText: "#ffffff",
    },
    monochrome: {
      light: "#16161d",
      main: "#0d0d0f",
      dark: "#000",
      opacity: "rgba(0,0,0,0.72)",
      contrastText: "#ffffff",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#ffffff",
    },
    warning: {
      light: "#ffb74d",
      main: "#ff9800",
      dark: "#f57c00",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    info: {
      light: "#64b5f6",
      main: "#2196f3",
      dark: "#1976d2",
      contrastText: "#ffffff",
    },
    success: {
      light: "#81c784",
      main: "#4caf50",
      dark: "#388e3c",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    safety: {
      light: "",
      main: "#61A132",
      dark: "",
      contrastText: "rgba(255, 255, 255, 0.87)",
    },
    black: {
      light: "#000",
      main: "#000",
      dark: "",
      contrastText: "rgba(255, 255, 255, 0.87)",
      contrastBg: "rgba(255, 255, 255, 0.21)",
    },
    green: {
      light: "#47b275",
      main: "#47b275",
      dark: "",
      contrastText: "rgba(255, 255, 255, 0.87)",
      background: "#F6FFF6",
    },
    greenneon: {
      light: "#32ff32",
      main: "#00ff05",
      dark: "#00dd03",
      contrastText: "#000000",
    },
    blue: {
      light: "#4f8ef9",
      main: "#3071E9",
      dark: "#474DFF",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    yellow: {
      light: "#ffc043",
      main: "#ffc043",
      dark: "",
      contrastText: "rgba(255, 255, 255, 0.87)",
    },
    red: {
      light: "#f25138",
      main: "#f25138",
      dark: "",
      contrastText: "rgba(255, 255, 255, 0.87)",
    },
    brown: {
      light: "#99644c",
      main: "#99644c",
      dark: "",
      contrastText: "rgba(255, 255, 255, 0.87)",
    },
    orange: {
      light: "#ff7d49",
      main: "#ff7d49",
      dark: "",
      contrastText: "rgba(255, 255, 255, 0.87)",
    },
    purple: {
      light: "#7356bf",
      main: "#7356bf",
      dark: "#A334FB",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    pink: {
      light: "#7356bf",
      main: "#7356bf",
      dark: "#A334FB",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    grey: {
      50: "#fafafa",
      100: "#f5f5f5",
      200: "#eeeeee",
      300: "#e0e0e0",
      400: "#bdbdbd",
      500: "#9e9e9e",
      600: "#757575",
      700: "#616161",
      800: "#424242",
      900: "#212121",
      A100: "#d5d5d5",
      A200: "#aaaaaa",
      A400: "#303030",
      A700: "#616161",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      tertiary: "rgba(0, 0, 0, 0.3)",
      highlight: "rgba(0, 0, 0, 1)",
      disabled: "rgba(0, 0, 0, 0.3)",
      link: "#0576fa",
    },
    divider: {
      main: "rgba(121,133,139)",
      opacity: 0.57,
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "rgba(0, 0, 0, 0.04)",
      hoverOpacity: 0.04,
      selected: "rgba(0, 0, 0, 0.08)",
      selectedOpacity: 0.08,
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
      disabledOpacity: 0.38,
      focus: "rgba(0, 0, 0, 0.12)",
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
  },
  overrides: {
    NavigationBar: {
      color: () => theme.palette.text.primary,
      attentionColor: () => theme.palette.red.main,
      activeColor: () => theme.palette.primary.main,
      mobileBreakpoint: () => theme.breakpoint.up.md,
      height: 64,
    },
    MenuLink: {
      fontSize: "1.06rem",
    },
  },
  breakpoint: {
    screens: {
      sm: "640px",
      md: "768px",
      lg: "1024px",
      xl: "1280px",
    },
    up: {
      sm: () =>
        `@media only screen and (min-width: ${theme.breakpoint.screens.sm})`,
      md: () =>
        `@media only screen and (min-width: ${theme.breakpoint.screens.md})`,
      lg: () =>
        `@media only screen and (min-width: ${theme.breakpoint.screens.lg})`,
      xl: () =>
        `@media only screen and (min-width: ${theme.breakpoint.screens.xl})`,
    },
    down: {
      sm: () =>
        `@media only screen and (max-width: ${theme.breakpoint.screens.sm})`,
      md: () =>
        `@media only screen and (max-width: ${theme.breakpoint.screens.md})`,
      lg: () =>
        `@media only screen and (max-width: ${theme.breakpoint.screens.lg})`,
      xl: () =>
        `@media only screen and (max-width: ${theme.breakpoint.screens.xl})`,
    },
  },

  screens: {
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
  },
  device: {
    sm: () => `@media only screen and (min-width: ${theme.screens.sm})`,
    md: () => `@media only screen and (min-width: ${theme.screens.md})`,
    lg: () => `@media only screen and (min-width: ${theme.screens.lg})`,
    xl: () => `@media only screen and (min-width: ${theme.screens.xl})`,
  },
  colors: {
    transparent: "transparent",

    black: "#000",
    white: "#fff",

    gray: {
      100: "#f7fafc",
      200: "#edf2f7",
      300: "#e2e8f0",
      400: "#cbd5e0",
      500: "#a0aec0",
      600: "#718096",
      700: "#4a5568",
      800: "#2d3748",
      900: "#1a202c",
    },
    red: {
      100: "#fff5f5",
      200: "#fed7d7",
      300: "#feb2b2",
      400: "#fc8181",
      500: "#f56565",
      600: "#e53e3e",
      700: "#c53030",
      800: "#9b2c2c",
      900: "#742a2a",
    },
    orange: {
      100: "#fffaf0",
      200: "#feebc8",
      300: "#fbd38d",
      400: "#f6ad55",
      500: "#ed8936",
      600: "#dd6b20",
      700: "#c05621",
      800: "#9c4221",
      900: "#7b341e",
    },
    yellow: {
      100: "#fffff0",
      200: "#fefcbf",
      300: "#faf089",
      400: "#f6e05e",
      500: "#ecc94b",
      600: "#d69e2e",
      700: "#b7791f",
      800: "#975a16",
      900: "#744210",
    },
    green: {
      100: "#f0fff4",
      200: "#c6f6d5",
      300: "#9ae6b4",
      400: "#68d391",
      500: "#48bb78",
      600: "#38a169",
      700: "#2f855a",
      800: "#276749",
      900: "#22543d",
    },
    teal: {
      100: "#e6fffa",
      200: "#b2f5ea",
      300: "#81e6d9",
      400: "#4fd1c5",
      500: "#38b2ac",
      600: "#319795",
      700: "#2c7a7b",
      800: "#285e61",
      900: "#234e52",
    },
    blue: {
      100: "#ebf8ff",
      200: "#bee3f8",
      300: "#90cdf4",
      400: "#63b3ed",
      500: "#4299e1",
      600: "#3182ce",
      700: "#2b6cb0",
      800: "#2c5282",
      900: "#2a4365",
    },
    indigo: {
      100: "#ebf4ff",
      200: "#c3dafe",
      300: "#a3bffa",
      400: "#7f9cf5",
      500: "#667eea",
      600: "#5a67d8",
      700: "#4c51bf",
      800: "#434190",
      900: "#3c366b",
    },
    purple: {
      100: "#faf5ff",
      200: "#e9d8fd",
      300: "#d6bcfa",
      400: "#b794f4",
      500: "#9f7aea",
      600: "#805ad5",
      700: "#6b46c1",
      800: "#553c9a",
      900: "#44337a",
    },
    pink: {
      100: "#fff5f7",
      200: "#fed7e2",
      300: "#fbb6ce",
      400: "#f687b3",
      500: "#ed64a6",
      600: "#d53f8c",
      700: "#b83280",
      800: "#97266d",
      900: "#702459",
    },
  },
  spacing: {
    px: "1px",
    0: "0",
    1: "0.25rem",
    2: "0.5rem",
    3: "0.75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    8: "2rem",
    9: "2.225rem",
    10: "2.5rem",
    12: "3rem",
    14: "3.6rem",
    16: "4rem",
    20: "5rem",
    24: "6rem",
    32: "8rem",
    40: "10rem",
    48: "12rem",
    56: "14rem",
    64: "16rem",
  },
  backgroundColor: theme => theme("colors"),
  backgroundPosition: {
    bottom: "bottom",
    center: "center",
    left: "left",
    "left-bottom": "left bottom",
    "left-top": "left top",
    right: "right",
    "right-bottom": "right bottom",
    "right-top": "right top",
    top: "top",
  },
  backgroundSize: {
    auto: "auto",
    cover: "cover",
    contain: "contain",
  },
  borderColor: theme => ({
    ...theme("colors"),
    default: theme("colors.gray.300", "currentColor"),
  }),
  borderRadius: {
    none: "0",
    sm: "0.125rem",
    default: "0.25rem",
    lg: "0.5rem",
    full: "9999px",
  },
  borderWidth: {
    default: "1px",
    0: "0",
    2: "2px",
    4: "4px",
    8: "8px",
  },
  boxShadow: {
    default: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    lg:
      "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
    xl:
      "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
    inner: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
    outline: "0 0 0 3px rgba(66, 153, 225, 0.5)",
    nav: "0 1px 4px 0 rgb(0 0 0 / 20%)",
    none: "none",
  },
  container: {},
  cursor: {
    auto: "auto",
    default: "default",
    pointer: "pointer",
    wait: "wait",
    text: "text",
    move: "move",
    "not-allowed": "not-allowed",
  },
  fill: {
    current: "currentColor",
  },
  flex: {
    1: "1 1 0%",
    auto: "1 1 auto",
    initial: "0 1 auto",
    none: "none",
  },
  flexGrow: {
    0: "0",
    default: "1",
  },
  flexShrink: {
    0: "0",
    default: "1",
  },
  fontFamily: {
    default: "Open Sans, sans-serif",
    headings: "Nunito, sans-serif",
  },
  fontSize: {
    xs: "0.75rem",
    sm: "0.875rem",
    base: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "2.6rem",
    "6xl": "3.2rem",
  },
  fontWeight: {
    hairline: "100",
    thin: "200",
    light: "300",
    normal: "400",
    medium: "500",
    semibold: "600",
    bold: "700",
    extrabold: "800",
    black: "900",
  },
  height: theme => ({
    auto: "auto",
    ...theme("spacing"),
    full: "100%",
    screen: "100vh",
  }),
  inset: {
    0: "0",
    auto: "auto",
  },
  letterSpacing: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em",
  },
  lineHeight: {
    none: "1",
    tight: "1.25",
    snug: "1.375",
    normal: "1.5",
    relaxed: "1.625",
    loose: "2",
  },
  listStyleType: {
    none: "none",
    disc: "disc",
    decimal: "decimal",
  },
  margin: (theme, { negative }) => ({
    auto: "auto",
    ...theme("spacing"),
    ...negative(theme("spacing")),
  }),
  maxHeight: {
    full: "100%",
    screen: "100vh",
  },
  maxWidth: {
    xs: "20rem",
    sm: "24rem",
    md: "28rem",
    lg: "32rem",
    xl: "36rem",
    "2xl": "42rem",
    "3xl": "48rem",
    "4xl": "56rem",
    "5xl": "64rem",
    "6xl": "72rem",
    full: "100%",
  },
  minHeight: {
    0: "0",
    full: "100%",
    screen: "100vh",
  },
  minWidth: {
    0: "0",
    full: "100%",
  },
  objectPosition: {
    bottom: "bottom",
    center: "center",
    left: "left",
    "left-bottom": "left bottom",
    "left-top": "left top",
    right: "right",
    "right-bottom": "right bottom",
    "right-top": "right top",
    top: "top",
  },
  opacity: {
    0: "0",
    25: "0.25",
    50: "0.5",
    75: "0.75",
    100: "1",
  },
  order: {
    first: "-9999",
    last: "9999",
    none: "0",
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
    10: "10",
    11: "11",
    12: "12",
  },
  padding: theme => theme("spacing"),
  stroke: {
    current: "currentColor",
  },
  textColor: theme => theme("colors"),
  width: theme => ({
    auto: "auto",
    ...theme("spacing"),
    "1/2": "50%",
    "1/3": "33.333333%",
    "2/3": "66.666667%",
    "1/4": "25%",
    "2/4": "50%",
    "3/4": "75%",
    "1/5": "20%",
    "2/5": "40%",
    "3/5": "60%",
    "4/5": "80%",
    "1/6": "16.666667%",
    "2/6": "33.333333%",
    "3/6": "50%",
    "4/6": "66.666667%",
    "5/6": "83.333333%",
    "1/12": "8.333333%",
    "2/12": "16.666667%",
    "3/12": "25%",
    "4/12": "33.333333%",
    "5/12": "41.666667%",
    "6/12": "50%",
    "7/12": "58.333333%",
    "8/12": "66.666667%",
    "9/12": "75%",
    "10/12": "83.333333%",
    "11/12": "91.666667%",
    full: "100%",
    screen: "100vw",
  }),
  zIndex: {
    auto: "auto",
    0: "0",
    10: "10",
    20: "20",
    30: "30",
    40: "40",
    50: "50",
  },
}

export default theme
